import { useQuery } from "@tanstack/react-query";
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export const QUERY_KEY_DIRECTOR = "get-director";

export const getDirectorFn = async (config: InternalAxiosRequestConfig): Promise<string> => {
  return axios(config).then(response => response?.data);
};

export const useApiGetDirMessage = (storeId: number, options?: UseQueryOptionsWithoutQueryKey<string>) => {
  const url = `/dir_message/${storeId}`;
  const config: InternalAxiosRequestConfig = { method: "get", url, headers: new AxiosHeaders() };
  return useQuery<string, Error>({
    queryKey: [QUERY_KEY_DIRECTOR, url],
    queryFn: () => getDirectorFn(config),
    ...options
  });
};
