import { queryOptions, useQuery } from "@tanstack/react-query";
import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosHeaders } from "axios";
import { Sport } from "core/models/api/sports-piloting/sports-piloting";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

interface ISport {
  id: number;
  label: string;
  store_label: string;
  store_id: number;
  department_id: number;
}
interface ISportsResponse {
  content: ISport[];
}

type QueryVariableWithIds = {
  ids: string | number[];
};

type QueryVariableWithStoreId = {
  storeId: number;
};

export type ISportVariables = QueryVariableWithStoreId | QueryVariableWithIds;

export const QUERY_KEY_SPORT = "get-sport";

const getSportsFn = async (config: InternalAxiosRequestConfig): Promise<Sport[]> => {
  const response: AxiosResponse<ISportsResponse> = await axios(config);
  return response?.data?.content;
};

export const useApiGetSport = (params: ISportVariables, options?: UseQueryOptionsWithoutQueryKey<Sport[]>) => {
  const url = "/sports";
  const config: InternalAxiosRequestConfig = { method: "get", url, params, headers: new AxiosHeaders() };
  const isEnabled =
    (params !== undefined &&
      Object.prototype.hasOwnProperty.call(params, "ids") &&
      (params as QueryVariableWithIds).ids !== undefined) ||
    (Object.prototype.hasOwnProperty.call(params, "storeId") &&
      (params as QueryVariableWithStoreId).storeId !== undefined);

  const generateOptions = queryOptions({
    queryKey: [QUERY_KEY_SPORT, params],
    queryFn: () => getSportsFn(config),
    enabled: isEnabled
  });

  return useQuery({
    ...generateOptions,
    ...options
  });
};
