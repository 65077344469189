import { memoize } from "lodash";
import update from "immutability-helper";
import { IRow as ITableRow } from "@decathlon/react-table";
import { getObjectKeysSortedByIndex, IObjectsWithIndex } from "core/utils/common";
import { IHeaders, Period, periodToDate } from "core/utils/headers";
import { IMask } from "core/api/rest/queries";
import { IAggregateHeader } from "core/utils/aggregates";
import {
  getFormulaAccordingToFormulaAttribute,
  getParentsMapping,
  getRoots,
  getUpdatedRowsData
} from "./vertical-engine";
import { getAggregates, getUpdatedAggregateFormulasData, getUpdatedAggregatesData } from "./horizontal-engine";
import { getPersistedTableRows } from "./table-updater";
import { ICells, INDICATORS } from "core/utils/accounts";
import { evaluate } from "mathjs";

export interface IIndexMapping {
  [columnId: string]: number;
}

export interface IImpactedRow {
  formulas: string[];
  aggregate_formula?: string;
  is_temporal_average?: boolean;
  weight: number;
}

export interface IPathRow {
  children: string[];
  is_hidden: boolean;
  is_child?: boolean;
}

export interface IPathRowWithIndex extends IPathRow {
  index: number;
}

export interface IRowWithCells {
  cells: ICells;
}

export interface IRowWithoutMask extends IRowWithCells, IImpactedRow, IPathRow {}

export interface IRow extends IRowWithoutMask {
  mask: IMask;
  is_persisted?: boolean;
  is_editable?: boolean;
  label_code?: string;
}

export interface IRows<R = IRow> {
  [id: string]: R;
}

export interface IRowsData<IGenericRows = IRows> {
  updatedComputedRowsCells: IComputedCells[];
  updatedRows: IGenericRows;
}

export interface IParentsMapping {
  [id: string]: string[];
}

export interface IPathCache {
  parentsMapping: IParentsMapping;
  rootRowIds: string[];
}

export interface IImpactedRows {
  label_code: string;
  impactedRows: string[];
}

export interface IRowsImpactsMapping {
  [id: string]: IImpactedRows[];
}

export interface IImpactsMapping {
  rowsImpactsMapping: IRowsImpactsMapping;
  aggregatesImpactsMapping: IRowsImpactsMapping;
}

export interface ICache extends IPathCache {
  columnsIndexMapping: IIndexMapping;
  aggregates: IHeaders<IAggregateHeader>;
  impactsMapping: IImpactsMapping;
}

export interface IParams {
  [id: string]: number | undefined;
}

export interface IRowCell {
  rowId: string;
  columnId: string;
  value: number;
  prevValue?: number;
}

export enum TypedValue {
  Realized = "R",
  Pilotable = "P",
  NotApplicable = "NA",
  PilotedAutomatically = "PA",
  PilotedManually = "PM",
  ExternalAPI = "API",
  Computed = "CO"
}

export interface IComputedCellValue {
  [columnIndex: string]: {
    value: number;
    type?: TypedValue;
  };
}

export interface IComputedCells {
  label_code: string;
  data: IComputedCellValue;
}

export interface IUpdatedRows<IGenericRows extends IRows> {
  newRows: IGenericRows;
  newRowsCellsToSave: IComputedCells[];
  newUpdatedAggregates: IComputedCells[];
  cleanedRowsCellsToSave: IComputedCells[];
  cleanedUpdatedAggregates: IComputedCells[];
}

export type FormulaAttribute = "formula" | "aggregate_formula";

export const getIndexMapping = memoize((objects: IObjectsWithIndex): IIndexMapping => {
  if (objects) {
    return getObjectKeysSortedByIndex(objects).reduce((propsMapping, prop, index) => {
      propsMapping[prop] = index;
      return propsMapping;
    }, {});
  }
  return {};
});

export const getCache = (rows: IRows<IPathRowWithIndex & IImpactedRow>, headers: IHeaders): ICache => {
  const parentsMapping = getParentsMapping(rows);
  const impactsMapping = getRowsImpactsMapping(rows);
  return {
    columnsIndexMapping: getIndexMapping(headers),
    parentsMapping: getParentsMapping(rows),
    impactsMapping,
    rootRowIds: getRoots(rows, parentsMapping),
    aggregates: getAggregates(headers)
  };
};

export const getRowsImpactsMapping = memoize((rows: IRows<IImpactedRow>): IImpactsMapping => {
  const rowsImpactsMapping = getFlattenRowsImpactsMapping(rows, "formula");
  const aggregatesImpactsMapping = getFlattenRowsImpactsMapping(rows, "aggregate_formula");
  return { rowsImpactsMapping, aggregatesImpactsMapping };
});

/**
 * Get the impacted row ids triggered by sourceRowId in a recursive way
 * @param rows object containing all the rows
 * @param rowsIds list of rows ids ASC SORTED by their weight
 * @param formulaAttribute row attribute to use the good formula type
 * @param sourceRowId source row id whose its modification should trigger new impacted rows
 * @param computedRowsIds list of rows ids that have been already processed and we don't want to recompute
 */
export const getFlattenRowImpactsMapping = (
  rows: IRows<IImpactedRow>,
  rowsIds: string[],
  formulaAttribute: FormulaAttribute,
  sourceRowId: string,
  computedRowsIds: string[] = []
): IImpactedRows[] => {
  const newComputedRowsIds = [...computedRowsIds, sourceRowId];

  return rowsIds.reduce<IImpactedRows[]>((result, rowId) => {
    if (!newComputedRowsIds.includes(rowId)) {
      const formula = getFormulaAccordingToFormulaAttribute(formulaAttribute, sourceRowId, rows[rowId]);
      /**
       * If there is a formula, it means that source row id trigger an impact on current row id
       */
      if (formula) {
        const impactedRowIndex = result.findIndex(impactedRow => impactedRow.label_code === sourceRowId);
        const impactedRow =
          impactedRowIndex > -1 ? result[impactedRowIndex] : { label_code: sourceRowId, impactedRows: [] };

        /** Create or add the source row id in IImpactedRows object and push the impacted row id*/

        impactedRow.impactedRows.push(rowId);
        if (impactedRowIndex === -1) {
          result.push(impactedRow);
        }
        /**
         * Get all row ids used in the triggered formula
         * And push them to newComputedRowsIds
         * Since they are used to compute the current impacted row id
         * We don't want to recompute them
         */
        const impactedRowIds = getIdsFromFormula([formula]);

        /** Since row id has been triggered and recomputed, we add it to newComputedRowsIds */
        newComputedRowsIds.push(...impactedRowIds, rowId);
        /** Get the sub impacted row ids triggered by the current impacted row id in a recursive way */
        const subImpactedRowsIds = getFlattenRowImpactsMapping(
          rows,
          rowsIds,
          formulaAttribute,
          rowId,
          newComputedRowsIds
        );
        /** We have to keep the previously calculated indicators (rows) */
        result.push(...subImpactedRowsIds);
      }
    }
    return result;
  }, []);
};

export const getFlattenRowsImpactsMapping = (
  rows: IRows<IImpactedRow>,
  formulaAttribute: FormulaAttribute
): IRowsImpactsMapping => {
  const rowsWithValidWeight = Object.entries(rows)
    ?.filter(([, definition]) => definition.weight !== undefined)
    ?.reduce((struct, [indicator, definition]) => {
      struct[indicator] = definition;
      return struct;
    }, {});

  const rowsIds = Object.keys(rowsWithValidWeight)?.sort(
    (idA, idB) => rowsWithValidWeight[idA].weight - rowsWithValidWeight[idB].weight
  );

  return rowsIds?.reduce<IRowsImpactsMapping>((result, rowId) => {
    /**
     * Get the whole impacted rows tree
     * impacted directly or indirecly by rowId
     */
    result[rowId] = getFlattenRowImpactsMapping(rows, rowsIds, formulaAttribute, rowId, []);
    return result;
  }, {});
};

const regexVariables = /(?:\{)([a-zA-Z0-9_\-\s@]*)(?:\})/g;

export const getIdsFromFormula = (formulas: string[]): string[] => {
  return formulas.reduce<string[]>((formulaIds, formula) => {
    let match = regexVariables.exec(formula);
    while (match !== null) {
      const formulaId = match[1];
      if (formulaId) {
        if (!formulaIds.includes(formulaId)) {
          formulaIds.push(formulaId);
        }
      }
      match = regexVariables.exec(formula);
    }
    return formulaIds;
  }, []);
};

export const getFormulaParams = (formula: string, rows: IRows<IRowWithCells>, columnId: string): IParams => {
  const paramsIds = getIdsFromFormula([formula]);
  return paramsIds.reduce<IParams>((params, paramId) => {
    params[paramId] = rows?.[paramId]?.cells?.[columnId]?.value;
    return params;
  }, {});
};

export const evalFormula = (formula: string, params: IParams): number => {
  let formattedFormula = Object.keys(params).reduce((result, param) => {
    const paramValue = params[param];
    if (paramValue === undefined) {
      throw new Error(`Cannot evaluate the following formula "${formula}", the param "${param}" is undefined`);
    }
    return result.replace(new RegExp(`\\{${param}\\}`, "g"), !params[param] ? "0" : params[param].toString());
  }, formula);
  // quick-fix to avoid the double substraction interpretation error
  formattedFormula = formattedFormula.replace(/--/g, "+").replace(/\+\+/g, "+");
  try {
    const result = evaluate(formattedFormula);
    return !isFinite(result) ? null : result;
  } catch (error) {
    console.error(`Error evaluating formula with MathJS: ${error.message}`);
    throw error;
  }
};

export const updateRowCellValue = (
  rows: IRows<IRowWithoutMask>,
  rowCell: IRowCell,
  type: TypedValue
): IRows<IRowWithoutMask> => {
  const { rowId, columnId, value } = rowCell;
  const row = rows[rowId];
  const cell = row && row.cells[columnId];
  if (cell) {
    const { initial_value } = cell;

    // @ts-ignore $apply required
    // warning if values = 0 it doesn't change value
    return update(rows, {
      [rowId]: {
        cells: {
          [columnId]: { value: { $set: value }, isEdited: { $set: value !== initial_value }, type: { $set: type } }
        }
      }
    });
  }

  return rows;
};

export const updateRowCellType = (
  rows: IRows<IRowWithoutMask>,
  rowCell: IRowCell,
  type: TypedValue
): IRows<IRowWithoutMask> => {
  const { rowId, columnId } = rowCell;
  const row = rows[rowId];
  const cell = row && row.cells[columnId];
  if (cell) {
    return update(rows, {
      [rowId]: { cells: { [columnId]: { type: { $set: type } } } }
    });
  }
  return rows;
};
export const getDataToPersist = (computedCells: IComputedCells[], rows: IRows<IRow>) =>
  computedCells.reduce<IComputedCells[]>((cellsToSave, computedRowCells) => {
    const row = rows[computedRowCells.label_code];
    const rowIsPersisted = row.is_persisted;
    if (rowIsPersisted) {
      return [...cellsToSave, computedRowCells];
    }

    const newComputedRowsCells = { ...computedRowCells };
    newComputedRowsCells.data = Object.keys(computedRowCells.data).reduce((dataToSave, columnIndex) => {
      const cell = row.cells[columnIndex];
      if (cell && cell.is_persisted) {
        dataToSave[columnIndex] = { ...computedRowCells.data[columnIndex] };
      }
      return dataToSave;
    }, {});

    if (Object.keys(newComputedRowsCells.data).length) {
      return [...cellsToSave, newComputedRowsCells];
    }

    return cellsToSave;
  }, []);

export const hasDataToPersist = (computedCells: IComputedCells[], rows: IRows<IRow>) =>
  computedCells.some(computedRowCells => {
    const row = rows[computedRowCells.label_code];
    if (row.is_persisted) {
      return true;
    }
    return Object.keys(computedRowCells.data).some(columnIndex => row.cells[columnIndex]?.is_persisted);
  });

export const updateInitialValues = <IGenericRows extends IRows<IRowWithCells> = IRows<IRowWithCells>>(
  computedCells: IComputedCells[],
  rows: IGenericRows
) => {
  let newRows = rows;
  computedCells.forEach(rowCells => {
    Object.keys(rowCells.data).forEach(columnId => {
      const row = newRows[rowCells.label_code];
      const cell = row && row.cells[columnId];
      if (cell) {
        // @ts-ignore '$apply' is not declared (immutability-helper)
        newRows = update(newRows, {
          [rowCells.label_code]: { cells: { [columnId]: { initial_value: { $set: rowCells.data[columnId].value } } } }
        });
      }
    });
  });
  return newRows;
};

export const updateComputedRowsCells = (
  computedCells: IComputedCells[],
  rowId: string,
  columnId: string,
  value: number,
  type: TypedValue = TypedValue.PilotedManually
) => {
  const updatedComputedRowsCells = [...computedCells];
  const computedRowCellsIndex = updatedComputedRowsCells.findIndex(item => rowId === item.label_code);
  if (computedRowCellsIndex !== -1) {
    updatedComputedRowsCells[computedRowCellsIndex].data[columnId] = { value, type };
  } else {
    updatedComputedRowsCells.push({ label_code: rowId, data: { [columnId]: { value, type } } });
  }
  return updatedComputedRowsCells;
};

/**
 * Transform cell id (Period) to a date. Used for the month by month engine
 * @param computedRowsCells
 */
export const getDatesFromPeriods = (computedRowsCells: IComputedCells[]) => {
  return computedRowsCells.reduce<IComputedCells[]>((result, rowCell) => {
    const newRowCell = { ...rowCell };
    newRowCell.data = Object.keys(newRowCell.data).reduce((data, period) => {
      const date = periodToDate(period as Period);
      data[date] = newRowCell.data[period];
      return data;
    }, {});
    result.push(newRowCell);
    return result;
  }, []);
};

//control the new pilotetd value to not depass 100% or not to be under 0%
const controlNewPilotedValue = (account: IRow, newRowCell: IRowCell): number => {
  if (account.mask?.is_negative === false && account.mask?.is_percentage === true && account.is_editable === true) {
    if (newRowCell.value > 1) {
      return 1;
    } else if (newRowCell.value < 0) {
      return 0;
    }
  }
  return newRowCell.value;
};

export const controlDepartmentHours = (
  account: IRow,
  newRowCell: IRowCell,
  nonCommercialHoursValue: number
): number => {
  if (account?.label_code === INDICATORS.DEPARTMENT_HOURS) {
    if (newRowCell.value < nonCommercialHoursValue) {
      return nonCommercialHoursValue;
    }
  }
  return newRowCell.value;
};

export const cleanComputedRowsCells = (computedRowsCells: IComputedCells[], rows: IRows): IComputedCells[] => {
  return computedRowsCells.reduce((cleanedComputedRowsCells, rowCells) => {
    let newComputedRowsCells = cleanedComputedRowsCells;
    Object.keys(rowCells.data).forEach(columnId => {
      const row = rows && rows[rowCells.label_code];
      const cell = row && row.cells[columnId];

      if (cell) {
        const { initial_value } = cell;
        const { value, type } = rowCells.data[columnId];
        const valueIsUnlocked = type !== TypedValue.PilotedManually;

        const valueHasChanged = initial_value !== value;
        /**
         * If the value has been changed or
         * If the modification type has been changed
         * Confirm the row cell to be compute
         */
        if (valueHasChanged || valueIsUnlocked) {
          newComputedRowsCells = updateComputedRowsCells(
            newComputedRowsCells,
            rowCells.label_code,
            columnId,
            value,
            type
          );
        }
      }
    });
    return newComputedRowsCells;
  }, []);
};

// utils
const roundValue = (decimal: number, value: number) => Math.round(decimal * value) / decimal;

const ensureIndicatorsHundredPercent = (inputCell: IRowCell, rows: IRows<IRow>, staticIndicatorName: INDICATORS) => {
  const inputValue = inputCell?.value || 0; // 0.3;
  const inputValueColumId = inputCell?.columnId; // "2022W29"
  const staticIndicatorValue = rows[staticIndicatorName]?.cells[inputValueColumId]?.value || 0; // 0.71
  const totalPart = inputValue + staticIndicatorValue; // 1.01
  // > 100 %
  if (totalPart > 1) {
    const inputValueRound = roundValue(10000, inputValue); // 0.3
    const staticIndicatorValueRound = roundValue(10000, staticIndicatorValue); // 0.71
    const difference = 1 - inputValueRound - staticIndicatorValueRound; // -0.010000000000000009
    // if difference negatif
    if (difference < 0) {
      inputCell.value = 1 - staticIndicatorValueRound; // 100% - staticIndicator value
    }
  }
};

export const setCellValue = <IGenericRows extends IRows>(
  newRowCell: IRowCell,
  type: TypedValue = TypedValue.PilotedManually,
  rows: IGenericRows,
  computedCells: {
    rowsCellsToSave: IComputedCells[];
    updatedAggregates: IComputedCells[];
  },
  cache: ICache,
  dateGetter?: (columnId: string) => Date
): IUpdatedRows<IGenericRows> => {
  const account = rows[newRowCell.rowId];
  newRowCell.value = controlNewPilotedValue(account, newRowCell);
  // vertical calculation (column calculation)
  const nonCommercialHoursValue = rows[INDICATORS.NON_COMMERCIAL_HOURS]?.cells[newRowCell?.columnId]?.value;
  newRowCell.value = controlDepartmentHours(account, newRowCell, nonCommercialHoursValue);

  if (newRowCell.rowId === INDICATORS.DIGITAL_STORES_PART_OF_INSTORE_TURNOVER) {
    ensureIndicatorsHundredPercent(newRowCell, rows, INDICATORS.DECATHLON_PRO_INSTORE_PART_OF_INSTORE_TURNOVER);
  }

  if (newRowCell.rowId === INDICATORS.DECATHLON_PRO_INSTORE_PART_OF_INSTORE_TURNOVER) {
    ensureIndicatorsHundredPercent(newRowCell, rows, INDICATORS.DIGITAL_STORES_PART_OF_INSTORE_TURNOVER);
  }

  const updatedRowsData = getUpdatedRowsData<IGenericRows>(
    rows,
    computedCells.rowsCellsToSave,
    newRowCell,
    type,
    cache
  );
  // horizontal calculation (for each modified cell of the impacted column)
  // cache.aggregates
  const updatedAggregatesData = getUpdatedAggregatesData<IGenericRows>(
    rows,
    updatedRowsData.updatedRows,
    cache.aggregates,
    updatedRowsData.updatedComputedRowsCells,
    computedCells.updatedAggregates,
    type,
    dateGetter
  );

  // vertical calculation (for each horizontal calculation)
  const updatedAggregateFormulasData = getUpdatedAggregateFormulasData<IGenericRows>(
    updatedAggregatesData.updatedRows,
    updatedAggregatesData.updatedComputedRowsCells,
    type,
    cache
  );

  const newUpdatedRows = updatedAggregateFormulasData.updatedRows;
  /**
   * remove from computeds cells and aggregates, data that are equals to initial values to prevent the save button activation
   */
  const cleanedRowsCellsToSave = cleanComputedRowsCells(updatedRowsData.updatedComputedRowsCells, newUpdatedRows);
  let cleanedUpdatedAggregates: IComputedCells[] = [];
  if (cleanedRowsCellsToSave.length > 0) {
    cleanedUpdatedAggregates = cleanComputedRowsCells(
      updatedAggregateFormulasData.updatedComputedRowsCells,
      newUpdatedRows
    );
  }
  return {
    newRows: newUpdatedRows,
    newRowsCellsToSave: updatedRowsData.updatedComputedRowsCells,
    newUpdatedAggregates: updatedAggregateFormulasData.updatedComputedRowsCells,
    cleanedRowsCellsToSave,
    cleanedUpdatedAggregates
  };
};

export const initializeValues = <IGenericRows extends IRows>(
  rows: IGenericRows,
  tableRows: ITableRow[],
  computedCells: {
    rowsCellsToSave: IComputedCells[];
    updatedAggregates: IComputedCells[];
  },
  cache: ICache,
  cellWithSubItemsIndex?: number
) => {
  // update data.accounts initial value to updated value
  let newRows = updateInitialValues<IGenericRows>(computedCells.rowsCellsToSave, rows);
  // update data.accounts initial value to updated aggregates value
  newRows = updateInitialValues<IGenericRows>(computedCells.updatedAggregates, newRows);
  // set isEdited to false on each persisted data
  let newTableRows = getPersistedTableRows(
    computedCells.rowsCellsToSave,
    tableRows,
    newRows,
    cache,
    cellWithSubItemsIndex
  );
  // set isEdited to false on each persisted aggregates data
  newTableRows = getPersistedTableRows(
    computedCells.updatedAggregates,
    newTableRows,
    newRows,
    cache,
    cellWithSubItemsIndex
  );
  return {
    newRows,
    newTableRows
  };
};

export const parseCellValue = (value: number | string | undefined): number => {
  return parseFloat((value || 0).toString());
};

export const removeSuffix = (inputString: string, suffixToRemove: string) => {
  // Check if the inputString ends with the specified suffix
  if (inputString.endsWith(suffixToRemove)) {
    // Remove the suffix using slice
    return inputString.slice(0, -suffixToRemove.length);
  }

  // If the suffix is not present, return the original string
  return inputString;
};
