import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { QUERY_KEY_JOBS_VIEWS } from "./useApiMenuGetAllJobsViews";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";

export const useApiMenuPostJobsViews = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: any) => {
      const url = "/navigation_menu";
      const config: InternalAxiosRequestConfig = {
        method: "put",
        url,
        data: { ...params },
        headers: new AxiosHeaders()
      };
      return axios(config);
    },
    onSuccess: () => {
      toast.info(I18n.t("menu.alert.save"));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_JOBS_VIEWS] });
    },
    onError: () => {
      toast.error(I18n.t("error.description"));
    }
  });
};
