export const TEMPLATES_MY_TEMPLATE = "templates.my_template";
export const TEMPLATES_DEFAULT_TEMPLATE = "templates.default_template";
export const TEMPLATES_CRUD_LIST_MY_TEMPLATE = "templates.crud.list_my_template";
export const TEMPLATES_CRUD_CREATE_MY_TEMPLATE = "templates.crud.create_my_template";
export const TEMPLATES_CRUD_EDIT_MY_TEMPLATE = "templates.crud.edit_my_template";
export const TEMPLATES_SUBTITLE_CREATED_TEMPLATE = "templates.subtitle.created_template";
export const TEMPLATES_SUBTITLE_SHARED_TEMPLATE = "templates.subtitle.shared_template";
export const TEMPLATES_CRUD_LIST_DEFAULT_TEMPLATE = "templates.crud.list_default_template";
export const TEMPLATES_CRUD_CREATE_DEFAULT_TEMPLATE = "templates.crud.create_default_template";
export const TEMPLATES_CRUD_EDIT_DEFAULT_TEMPLATE = "templates.crud.edit_default_template";
export const TEMPLATES_SUBTITLE_CREATED_DEFAULT_TEMPLATE = "templates.subtitle.created_default_template";
export const TEMPLATES_FORM_TEMPLATE_NAME = "templates.form.template_name";
export const TEMPLATES_FORM_TEMPLATE_COMPOSITION = "templates.form.template_composition";
export const TEMPLATES_FORM_AVAILABLE_ACCOUNTS = "templates.form.available_accounts";
export const TEMPLATES_FORM_TEMPLATE_ACCOUNTS = "templates.form.template_accounts";
export const TEMPLATES_FORM_BUTTON_DELETE = "templates.form.button_delete";
