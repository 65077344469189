import { useQuery } from "@tanstack/react-query";
import axios, { AxiosHeaders, InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { ISportsGroup } from "../../models/api/sports-group/sports-group-piloting";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

interface ISportsGroupResponseContent {
  sport_id: number;
  index: number;
}

interface ISportsGroupResponseContentDto {
  id: number;
  label: string;
  composition: ISportsGroupResponseContent[];
  contains_unique_store: boolean;
  main_store_id: number;
  can_be_use_on_comparison_screen: boolean;
  contains_more_than30_department: boolean;
}

interface ISportsGroupResponseDto {
  content: ISportsGroupResponseContentDto[];
}

export const QUERY_KEY_SPORTS_GROUP = "get-sports-group";

const getSportsGroupFn = async (config: InternalAxiosRequestConfig): Promise<ISportsGroup[]> => {
  const axiosPromise: AxiosResponse<ISportsGroupResponseDto> = await axios(config);
  return axiosPromise.data.content.map(sg => {
    return {
      id: sg.id,
      label: sg.label,
      composition: sg.composition.map(composition => {
        return {
          sport_id: composition.sport_id,
          index: composition.index
        };
      }),
      contains_unique_store: sg.contains_unique_store,
      main_store_id: sg.main_store_id,
      can_be_use_on_comparison_screen: sg.can_be_use_on_comparison_screen,
      contains_more_than30_department: sg.contains_more_than30_department
    };
  });
};

export const useApiGetSportsGroup = (options?: UseQueryOptionsWithoutQueryKey<ISportsGroup[]>) => {
  const url = "/sports_groups";
  const config: InternalAxiosRequestConfig = { method: "get", url, headers: new AxiosHeaders() };
  return useQuery<ISportsGroup[]>({
    queryKey: [QUERY_KEY_SPORTS_GROUP, url],
    queryFn: () => getSportsGroupFn(config),
    staleTime: Infinity,
    ...options
  });
};
