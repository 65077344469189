import SubheaderGroups from "./subheader-groups";
import { RouteId } from "core/routing/router/route-ids";
import { getPathFromRouteId } from "core/utils/route-utils";
import { I18n } from "react-redux-i18n";
import { SETTINGS_HOME } from "core/constants/translations";
import { VtmnBreadcrumb, VtmnBreadcrumbItem } from "@vtmn/react";
import React from "react";

export interface IBreadcrumbs {
  routeId: RouteId;
  name: string;
}
export interface ISubheaderComponent {
  routeId?: string;
  title: string;
  description: string;
  shortcutSportsGroups: boolean;
  shortcutStoresGroups: boolean;
  breadcrumbs: IBreadcrumbs[];
  isVisibleSubheader: boolean;
}

export interface IInformations {
  isCurrentPage?: boolean;
  routeId?: string;
  name?: string;
}

const SubheaderComponent = ({
  routeId,
  title,
  description,
  shortcutSportsGroups,
  shortcutStoresGroups,
  breadcrumbs,
  isVisibleSubheader
}: ISubheaderComponent) => {
  const shouldHomePage = (routeId: string) =>
    routeId === RouteId.Home ||
    routeId === RouteId.HomeSport ||
    routeId === RouteId.HomeTeam ||
    routeId === RouteId.HomeStore ||
    routeId === RouteId.HomeNew;

  const homepageInformations = shouldHomePage ? { name: SETTINGS_HOME, routeId: RouteId.Home } : null;
  const titleOfCurrentPage = { name: title, routeId: routeId, isCurrentPage: true };
  const descriptionOfCurrentPage = `\xa0- ${description}`;
  const links = breadcrumbs || [];
  const allInformations: IInformations[] = [homepageInformations, ...links, titleOfCurrentPage];

  return (
    <>
      {isVisibleSubheader ? (
        <div className="mg-subheader">
          <div className="mg-subheader-left-navigation">
            <VtmnBreadcrumb aria-label="Breadcrumb">
              {allInformations?.map((informations, index) => (
                <React.Fragment key={index}>
                  {informations?.routeId && (
                    <VtmnBreadcrumbItem
                      {...(informations?.routeId ? { href: getPathFromRouteId(RouteId[informations?.routeId]) } : {})}
                    >
                      <span
                        {...(informations?.isCurrentPage ? { "data-testid": "breadcrumb-current-page" } : {})}
                        className={informations?.isCurrentPage ? "mg-subheader-left-navigation__bold" : ""}
                      >
                        {informations?.name && I18n.t(informations?.name)}
                      </span>
                    </VtmnBreadcrumbItem>
                  )}
                </React.Fragment>
              ))}
            </VtmnBreadcrumb>
            {descriptionOfCurrentPage && <span>{descriptionOfCurrentPage}</span>}
          </div>
          <div className="mg-subheader-right-navigation">
            <SubheaderGroups shortcutSportsGroups={shortcutSportsGroups} shortcutStoresGroups={shortcutStoresGroups} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SubheaderComponent;
