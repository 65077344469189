import LoadingProgress from "components/common/loader/loading-progress";
import ErrorCardMessage from "components/common/cards/error-card-message";
import { IUpwardForecast } from "core/api/rest/queries/upward-forecast";
import { useApiGetForecastByCountryCode } from "core/api/upward-forecast/useApiGetForecastByCountryCode";
import { JSX } from "react";
interface IChildrenProps {
  data: IUpwardForecast;
}

interface IProps {
  children?: (props: IChildrenProps) => JSX.Element | JSX.Element[];
  country: string;
}

export const UpwardForecastRequestsManager = ({ children, country }: IProps) => {
  const { data, isLoading, isError, refetch } = useApiGetForecastByCountryCode({ countryCode: country });
  return (
    <>
      {isLoading ? <LoadingProgress /> : null}
      {isError ? <ErrorCardMessage refetch={refetch} /> : null}
      {!isError && data ? children({ data: data }) : null}
    </>
  );
};

export default UpwardForecastRequestsManager;
