export enum PERMISSIONS {
  PILOTING_DAILY_PILOTING = "PILOTING_DAILY_PILOTING",
  PILOTING_WEEKLY_ACCESS = "PILOTING_WEEKLY_ACCESS",
  PILOTING_SPORTS_ACCESS = "PILOTING_SPORTS_ACCESS",
  PILOTING_HOURS_ACCESS = "PILOTING_HOURS_ACCESS",
  PILOTING_AREAS_ACCESS = "PILOTING_AREAS_ACCESS",
  PILOTING_ANNUAL_INITIALIZATION_ACCESS = "PILOTING_ANNUAL_INITIALIZATION_ACCESS",
  PILOTING_COMPARISON_ACCESS = "PILOTING_COMPARISON_ACCESS",
  PILOTING_MANAGER_BY_MONTH = "PILOTING_MANAGER_BY_MONTH",

  SETTINGS_USER_PREFERENCES_ACCESS = "SETTINGS_USER_PREFERENCES_ACCESS",
  SETTINGS_FAVORITE_SPORTS_ACCESS = "SETTINGS_FAVORITE_SPORTS_ACCESS",
  SETTINGS_TEMPLATES_ACCESS = "SETTINGS_TEMPLATES_ACCESS",

  WELCOME_PAGE_DIRECTOR_MESSAGE_EDIT_ACTION = "WELCOME_PAGE_DIRECTOR_MESSAGE_EDIT_ACTION",
  WELCOME_PAGE_COUNTRY_MESSAGE_EDIT_ACTION = "WELCOME_PAGE_COUNTRY_MESSAGE_EDIT_ACTION",

  EVENTS_ACCESS = "EVENTS_ACCESS",
  UPWARD_FORECAST_ACCESS = "UPWARD_FORECAST_ACCESS",

  REPORTING_EXTRACTION = "REPORTING_EXTRACTION",

  ADMIN_ONLY = "ADMIN_ONLY"
}

export enum ROLES {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_FINANCE = "ROLE_FINANCE",
  ROLE_STORE_MANAGER = "ROLE_STORE_MANAGER",
  ROLE_CASHIER_MANAGER = "ROLE_CASHIER_MANAGER",
  ROLE_DEPARTMENT_MANAGER = "ROLE_DEPARTMENT_MANAGER",
  ROLE_SPORT_LEADER = "ROLE_SPORT_LEADER"
}

const ALL_PERMISSIONS: PERMISSIONS[] = Object.values(PERMISSIONS);

const COMMON_PERMISSIONS: PERMISSIONS[] = [
  PERMISSIONS.PILOTING_WEEKLY_ACCESS,
  PERMISSIONS.PILOTING_SPORTS_ACCESS,
  PERMISSIONS.PILOTING_MANAGER_BY_MONTH,
  PERMISSIONS.PILOTING_ANNUAL_INITIALIZATION_ACCESS,
  PERMISSIONS.PILOTING_COMPARISON_ACCESS,
  PERMISSIONS.SETTINGS_USER_PREFERENCES_ACCESS,
  PERMISSIONS.SETTINGS_FAVORITE_SPORTS_ACCESS,
  PERMISSIONS.SETTINGS_TEMPLATES_ACCESS,
  PERMISSIONS.EVENTS_ACCESS,
  PERMISSIONS.PILOTING_DAILY_PILOTING,
  PERMISSIONS.PILOTING_HOURS_ACCESS,
  PERMISSIONS.PILOTING_AREAS_ACCESS,
  PERMISSIONS.WELCOME_PAGE_DIRECTOR_MESSAGE_EDIT_ACTION
];

const FINANCE_PERMISSIONS: PERMISSIONS[] = [
  ...COMMON_PERMISSIONS,
  PERMISSIONS.UPWARD_FORECAST_ACCESS,
  PERMISSIONS.WELCOME_PAGE_COUNTRY_MESSAGE_EDIT_ACTION,
  PERMISSIONS.REPORTING_EXTRACTION
];

const ROLES_MAPPING: Record<ROLES, PERMISSIONS[]> = {
  ROLE_ADMIN: ALL_PERMISSIONS,
  ROLE_SPORT_LEADER: COMMON_PERMISSIONS,
  ROLE_CASHIER_MANAGER: COMMON_PERMISSIONS,
  ROLE_DEPARTMENT_MANAGER: COMMON_PERMISSIONS,
  ROLE_STORE_MANAGER: COMMON_PERMISSIONS,
  ROLE_FINANCE: FINANCE_PERMISSIONS
};

export default ROLES_MAPPING;
