import { useQuery } from "@tanstack/react-query";
import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosHeaders } from "axios";
import { IForecastByCountryCode } from "../../models/api/upward-forecast/updward-forecast-by-country-code";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

interface IForecastParams {
  countryCode: string;
}

interface IForecastExceptionStoreDto {
  id: number;
  since: string;
  activated: boolean;
  country_code: string;
  store_id: number;
  store_label: string;
}

interface IForecastDto {
  activated: boolean;
  authorized: boolean;
  country_code: string;
  exception_stores: IForecastExceptionStoreDto[];
}

export const QUERY_KEY_FORECAST_BY_COUNTRY_CODE = "get-forecast";

const getForecastByCountryCodeFn = async (config: InternalAxiosRequestConfig): Promise<IForecastByCountryCode> => {
  const axiosPromise: AxiosResponse<IForecastDto> = await axios(config);
  return {
    activated: axiosPromise.data.activated,
    authorized: axiosPromise.data.authorized,
    country_code: axiosPromise.data.country_code,
    exception_stores: axiosPromise.data.exception_stores.map(es => {
      return {
        id: es.id,
        since: es.since,
        activated: es.activated,
        country_code: es.country_code,
        store_id: es.store_id,
        store_label: es.store_label
      };
    })
  };
};

export const useApiGetForecastByCountryCode = (
  params: IForecastParams,
  options?: UseQueryOptionsWithoutQueryKey<IForecastDto>
) => {
  const url = `/forecast/${params.countryCode}`;
  const config: InternalAxiosRequestConfig = { method: "get", url, headers: new AxiosHeaders() };
  return useQuery<IForecastDto>({
    queryKey: [QUERY_KEY_FORECAST_BY_COUNTRY_CODE, url],
    queryFn: () => getForecastByCountryCodeFn(config),
    ...options
  });
};
