import * as React from "react";
import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import MyGameIcon from "components/common/icons/icon";
import { uniqueId } from "lodash";

const HistoryLoading: React.FunctionComponent = () => {
  return (
    <List data-testid="history-dialog-skeleton">
      {[0, 1].map((_item, index) => {
        const showDivider = index !== 1;
        return (
          <React.Fragment key={`skeleton-li-${uniqueId()}`}>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <MyGameIcon className="datepicker__icon__calendar" name="week" />
              </ListItemIcon>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton variant="text" width={40} />} />
            </ListItem>
            {showDivider ? <Divider variant="inset" component="li" /> : null}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default HistoryLoading;
