export const MENU_SECTION_CONFIGURATION_COMMERCIAL = "menu.section.configuration_commercial";
export const MENU_SECTION_CONFIGURATION_HOURS = "menu.section.configuration_hours";
export const MENU_SECTION_DATA_VISUALIZATION = "menu.section.data-visualization";
export const MENU_SECTION_ANIMATION = "menu.section.animation";
export const MENU_ANIMATION_LINKS_GROUPS_WEEKLY = "menu.animation.links-groups.weekly";
export const MENU_ANIMATION_LINKS_GROUPS_SPORT = "menu.animation.links-groups.sport";
export const MENU_ANIMATION_LINKS_GROUPS_COMPARISON = "menu.animation.links-groups.comparison";
export const MENU_ANIMATION_LINKS_GROUPS_ANNUAL_INITIALIZATION = "menu.animation.links-groups.annual-initialization";
export const MENU_ANIMATION_SUBLINK_SPORT = "menu.sublink.sport";
export const MENU_ANIMATION_SUBLINK_SPORTS_GROUPS = "menu.sublink.sports-groups";
export const MENU_ANIMATION_SUBLINK_STORE = "menu.sublink.store";
export const MENU_ANIMATION_SUBLINK_TEAM = "menu.sublink.team";
export const MENU_CONFIGURATION_VUE_TEXT = "menu.configuration.vue.text";
export const MENU_DATA_VISUALIZATION_VISUALIZATION_BY_WEEKS = "menu.data_visualization.visualization_by_weeks";
export const MENU_DATA_VISUALIZATION_COMPARISON_BY_WEEKS = "menu.data_visualization.comparison_by_weeks";
export const MENU_MODAL_CANCEL = "menu.modal.cancel";
export const MENU_MODAL_SAVE = "menu.modal.save";
export const MENU_MODAL_CREATE = "menu.modal.create";
export const MENU_ALERT_SAVE = "menu.alert.save";
export const MENU_ALERT_CREATE = "menu.alert.create";
export const MENU_MESSAGE_EDITING = "menu.message.editing";
