import { AxiosHeaders, InternalAxiosRequestConfig } from "axios";

import { getMyGameLanguage } from "core/utils/i18n";
import getAppStore from "core/redux/store";
import qs from "qs";

const store = getAppStore();

const setLanguage = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
  const headers = new AxiosHeaders({
    ...config.headers,
    "accept-language": getMyGameLanguage()
  });

  return {
    ...config,
    headers
  };
};

export const setParamsSerializer = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const paramsSerializer = (params: any) => qs.stringify(params, { arrayFormat: "repeat" });
  return {
    ...config,
    paramsSerializer
  };
};

const setToken = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
  const { token } = store.getState().user;

  const headers = new AxiosHeaders({
    ...config.headers,
    "content-type": "application/json",
    authorization: `Bearer ${token}`
  });
  return {
    ...config,
    headers,
    timeout: parseInt(import.meta.env.REACT_APP_REQUEST_TIMEOUT)
  };
};

const addApiKeyHeader =
  (
    apiKey?: string
  ): ((request: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>) =>
  (request: InternalAxiosRequestConfig) => {
    const headers = new AxiosHeaders({
      ...request.headers,
      ...(apiKey != null && { "x-api-key": apiKey })
    });
    return {
      ...request,
      headers
    };
  };
// API_MYGAME_SPORT_API_KEY
const commonInterceptors = [
  { onFulfilled: setToken },
  { onFulfilled: setLanguage },
  { onFulfilled: setParamsSerializer }
];

export const sportInterceptors = [
  ...commonInterceptors,
  { onFulfilled: addApiKeyHeader(import.meta.env.REACT_APP_API_MYGAME_SPORT_API_KEY) }
];
