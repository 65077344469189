import { FC, memo, ReactElement, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { IDataGroup, Select } from "components/common/select";
import { IData } from "components/common/select/search";
import { Store } from "core/models/store";
import ArrayUtils from "core/utils/array";
import SelectAll from "../select/actions/select-all";
import { useApiGetStoresGroup } from "../../../core/api/stores-group/useApiGetStoresGroup";
import { StoresGroup } from "../../../core/models/stores-group";
import { useApiGetStores } from "../../../core/api/store/useApiGetStores";
import { Sport } from "core/models/api/sports-piloting/sports-piloting";
import { useApiMenuGetUserPreferences } from "../../../core/api/menu/useApiMenuGetUserPreferences";

export interface IProps {
  id?: string;
  storeId?: number | number[];
  className?: string;
  label?: string;
  value?: number | number[];
  favoriteStore?: number;
  hasBottomLine?: boolean;
  onChange?: (value: number | number[]) => void;
  disableDefaultStore?: boolean;
  isMultiple?: boolean;
  isDisabled?: boolean;
  disabledItemsIds?: number[];
  stores_group?: number;
  canBeReset?: boolean;
}

export interface IDumbProps extends IProps {
  data?: Store[];
  isLoading?: boolean;
}

type SelectedStore = number | number[];

const dataLabelFormat = (data: Store) => `${data.id} - ${data.label}`;
export const renderSelectAction = () => <SelectAll />;

const sortStore = (a: Store, b: Store) => Number(a.id) - Number(b.id);

const generateSelectData = (dataSourcePredicate: Sport[], favoriteStore: number, sortedReducedStoreList: Sport[]) => {
  const selectData: { favoriteStores?: IDataGroup; stores?: IDataGroup } = {};

  if (dataSourcePredicate.length && favoriteStore) {
    selectData.favoriteStores = {
      label: I18n.t("filters.favorite.label"),
      data: dataSourcePredicate.map(selectItem => ({
        ...selectItem,
        rightIcon: "star_full"
      }))
    };
  }

  selectData.stores = { data: sortedReducedStoreList };

  return selectData;
};

const getRenderedValue = (
  selectedStore: SelectedStore,
  data: Store[],
  isMultiple: boolean,
  dataLabelFormat: (data: Store) => string
) => {
  if (!data.length) {
    return I18n.t("loading");
  }

  if (!isMultiple) {
    if (typeof selectedStore === "number") {
      const store = data.find(item => item.id == selectedStore);
      return store ? dataLabelFormat(store) : I18n.t("settings.select_store");
    }
  }
  if (Array.isArray(selectedStore)) {
    if (selectedStore.length === 1) {
      const store = data.find(item => item.id == selectedStore[0]);
      return store ? dataLabelFormat(store) : I18n.t("settings.select_store");
    }
    // Handle multiple selected values
    return I18n.t("filters.multiple_selected_values");
  }

  return I18n.t("settings.select_store");
};

export const DumbSelectStore: FC<IDumbProps> = memo<IDumbProps>(
  ({
    className,
    id,
    label,
    isLoading,
    value,
    favoriteStore,
    onChange,
    hasBottomLine,
    data = [],
    disableDefaultStore,
    isMultiple,
    isDisabled,
    disabledItemsIds,
    canBeReset
  }) => {
    const emptyDefaultValue = isMultiple ? [] : null;
    const [store, setStore] = useState(disableDefaultStore ? emptyDefaultValue : value);
    const predicate = data => favoriteStore === data.id;
    const [dataSourcePredicate, dataSourceReduce]: Sport[][] = ArrayUtils.partition(data, predicate || (() => false));
    const sortedReducedStoreList = [...dataSourceReduce].sort(sortStore);

    const handleChange = useCallback(
      (store_id: number) => {
        setStore(store_id);
        onChange(store_id);
      },
      [onChange]
    );

    useEffect(() => {
      if (value !== store) {
        setStore(value);
      }
    }, [value]);

    const renderValue = useCallback(
      selectedStore => getRenderedValue(selectedStore, data, isMultiple, dataLabelFormat),
      [data, isMultiple, dataLabelFormat]
    );

    const selectData: IData = generateSelectData(dataSourcePredicate, favoriteStore, sortedReducedStoreList);

    return (
      <Select
        id={id || "toolbar-filters_store"}
        data={selectData}
        value={value || null}
        label={label || I18n.t("store")}
        placeholder={I18n.t("settings.select_store")}
        searchPlaceholder={I18n.t("settings.search_store")}
        className={classnames("filter-select", className)}
        hasBottomLine={hasBottomLine}
        renderValue={renderValue}
        onChange={handleChange}
        selectItemLabelFormat={dataLabelFormat}
        isLoading={isLoading}
        isVirtualized
        isSearchable
        isMultiple={isMultiple}
        isDisabled={isDisabled}
        disabledItemsIds={disabledItemsIds}
        canBeReset={canBeReset}
        renderActions={isMultiple ? renderSelectAction : null}
      />
    );
  }
);

export const getStoreListByStoreGroup = (storeGroups: StoresGroup[], storesGroupId: number, data: Store[]): Store[] => {
  if (!storeGroups || !storesGroupId || !data) {
    return data;
  }

  const storeGroupSelect = storeGroups.find(store => store?.id === storesGroupId);
  const storesIdByStoreGroupSelected = storeGroupSelect?.composition;
  return data.filter(storeElement => storesIdByStoreGroupSelected?.some(item => item?.store_id === storeElement?.id));
};

export const SelectStore = (props: IProps): ReactElement => {
  const value = useSelector(state => (props.storeId !== undefined ? props.storeId : get(state, "filters.store_id")));
  const { data: user } = useApiMenuGetUserPreferences();
  const favoriteStore = user.preferences.default_filters.store_id;
  const { data: stores, isLoading: loading } = useApiGetStores();
  const { data: storesGroup, isLoading: storesGroupLoading } = useApiGetStoresGroup();

  const storesGroupId = props?.stores_group;

  if (storesGroupId && storesGroupId !== -1) {
    // show only stores by storesGroupId selected
    return (
      <DumbSelectStore
        {...props}
        data={getStoreListByStoreGroup(storesGroup, storesGroupId, stores) || stores}
        favoriteStore={favoriteStore}
        isLoading={loading || storesGroupLoading}
        disableDefaultStore={props?.disableDefaultStore ?? false}
      />
    );
  } else {
    return <DumbSelectStore data={stores} favoriteStore={favoriteStore} value={value} isLoading={loading} {...props} />;
  }
};

export default SelectStore;
