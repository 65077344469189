import NavigationPrompt from "react-router-prompt";
import SaveModal from "components/common/modals/save-modal";
import { UpwardForecastExceptionManagerContext } from "components/specific/upward-forecast/data-manager/upward-forecast-exception-context";
import { useApiGetForecastByCountryCode } from "core/api/upward-forecast/useApiGetForecastByCountryCode";
import { useApiPostForecast } from "core/api/upward-forecast/useApiPostForecast";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { JSX } from "react";
export interface IChildrenProps {
  saveData: () => void;
  setIsSaveInProgress: Dispatch<SetStateAction<boolean>>;
}

interface IProps {
  countryId: string;
  children: (props: IChildrenProps) => JSX.Element | JSX.Element[];
}

const UpwardForecastExceptionManager = ({ countryId, children }: IProps) => {
  const { data } = useApiGetForecastByCountryCode({ countryCode: countryId });
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [newExceptionStoreId, setNewExceptionStoreId] = useState<number>(null);
  const { mutate } = useApiPostForecast();

  const updateException = useCallback((storeId: number) => {
    setNewExceptionStoreId(storeId);
  }, []);

  const clearException = useCallback(() => {
    setNewExceptionStoreId(null);
  }, []);

  const getException = useCallback(
    exceptionId => {
      return data?.exception_stores.find(exception => exception.id === exceptionId);
    },
    [data]
  );

  const saveNewException = () => {
    mutate({ countryCode: countryId, storeId: newExceptionStoreId });
    setIsSaveInProgress(false);
  };

  const hasDataBeenChanged = !!newExceptionStoreId;

  const contextValue = useMemo(() => {
    return {
      newExceptionStoreId,
      getException,
      updateException,
      clearException,
      saveNewException,
      hasDataBeenChanged,
      isSaveInProgress
    };
  }, [
    newExceptionStoreId,
    getException,
    updateException,
    clearException,
    saveNewException,
    hasDataBeenChanged,
    isSaveInProgress
  ]);
  return (
    <UpwardForecastExceptionManagerContext.Provider value={contextValue}>
      <NavigationPrompt when={hasDataBeenChanged}>
        {({ onConfirm, onCancel, isActive }) => (
          <SaveModal
            open={isActive}
            save={() => {
              saveNewException();
              onConfirm();
            }}
            close={onCancel}
            callbackWithoutSave={onConfirm}
          />
        )}
      </NavigationPrompt>
      {children({ saveData: saveNewException, setIsSaveInProgress })}
    </UpwardForecastExceptionManagerContext.Provider>
  );
};

export default UpwardForecastExceptionManager;
