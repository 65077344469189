import { FC, useCallback, useMemo } from "react";
import { I18n } from "react-redux-i18n";
import classnames from "classnames";
import { get } from "lodash";
import { JSX } from "react";

import { Select } from "components/common/select";
import { IData } from "components/common/select/search";
import useObjFromList from "core/hooks/useObjFromList";
import { ICountry } from "core/api/rest/queries/upward-forecast-country";
import useSelector from "core/hooks/useSelector";
import {
  FILTERS_LABELS_COUNTRIES,
  FILTERS_SEARCH_COUNTRIES,
  FILTERS_SELECTS_COUNTRIES
} from "../../../core/constants/translations/filters";
import { useCountries } from "../../../core/api/country/useCountries";

interface IProps {
  id?: string;
  className?: string;
  label?: string;
  value?: string | string[];
  hasBottomLine?: boolean;
  onChange?: (value: string | string[]) => void;
  isDisabled?: boolean;
}

interface IDumbProps extends IProps {
  data?: ICountry[];
  isLoading?: boolean;
}

const dataLabelFormat = (data: ICountry) => data.label;

const sortCountry = (countryA: ICountry, countryB: ICountry) => {
  const countryALabel = countryA.label;
  const countryBLabel = countryB.label;
  if (countryALabel < countryBLabel) {
    return -1;
  }
  return countryALabel === countryBLabel ? 0 : 1;
};

export const DumbSelectCountry: FC<IDumbProps> = ({
  className,
  id,
  label,
  isLoading,
  value,
  onChange,
  hasBottomLine,
  data = [],
  isDisabled
}) => {
  const sortedCountries = useMemo(() => {
    const sortedData = [...data];
    sortedData.sort(sortCountry);
    return sortedData;
  }, [data]);

  const handleChange = useCallback(
    (countryId: string) => {
      onChange(countryId);
    },
    [onChange]
  );

  const countriesMap = useObjFromList(data, "id");
  const renderValue = useCallback(
    (selectedCountry: string | string[]) => {
      if (!data.length) {
        return I18n.t("loading");
      }
      const country = countriesMap[selectedCountry as string];
      return country ? dataLabelFormat(country) : I18n.t("select.select_country");
    },
    [countriesMap, data]
  );

  const selectData: IData = {
    countries: { data: sortedCountries }
  };

  return (
    <Select
      id={id || "toolbar-select-country"}
      data={selectData}
      value={value}
      label={label || I18n.t(FILTERS_LABELS_COUNTRIES)}
      placeholder={I18n.t(FILTERS_SELECTS_COUNTRIES)}
      searchPlaceholder={I18n.t(FILTERS_SEARCH_COUNTRIES)}
      className={classnames("filter-select select-with-auto", className)}
      hasBottomLine={hasBottomLine}
      renderValue={renderValue}
      onChange={handleChange}
      selectItemLabelFormat={dataLabelFormat}
      isLoading={isLoading}
      isVirtualized
      isSearchable
      isDisabled={isDisabled}
    />
  );
};

export const SelectCountry = (props: IProps): JSX.Element => {
  const value = useSelector(state => get(state, "filters.country"));
  const { data: countries, isLoading: loading } = useCountries();
  return <DumbSelectCountry data={countries || []} value={value} isLoading={loading} {...props} />;
};

export default SelectCountry;
