import { useQuery } from "@tanstack/react-query";
import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosHeaders } from "axios";
import { Account, Accounts } from "../../models/accounts";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export interface AccountDto {
  formulas: string[];
  aggregate_formula: string;
  is_temporal_average: boolean;
  is_persisted: boolean;
  is_visible: boolean;
  is_store_aggregate: boolean;
  label: string;
  label_code: string;
  mask: MaskDto;
  weight: number;
}

interface MaskDto {
  decimals: number;
  is_percentage: boolean;
  is_negative: boolean;
  is_false_positive: boolean;
}

interface ResponseDto {
  content: AccountDto[];
}

const QUERY_KEY_ACCOUNT = "get-account";

const getAccountsFn = async (config: InternalAxiosRequestConfig): Promise<Accounts> => {
  const response: AxiosResponse<ResponseDto> = await axios(config);
  const accountList = response.data.content.map(item => {
    const account: Account = {
      formulas: item.formulas,
      aggregate_formula: item.aggregate_formula,
      is_temporal_average: item.is_temporal_average,
      is_persisted: item.is_persisted,
      is_visible: item.is_visible,
      is_store_aggregate: item.is_store_aggregate,
      label: item.label,
      label_code: item.label_code,
      mask: item.mask,
      weight: item.weight
    };
    return account;
  });

  const accounts: Accounts = {};
  for (const account of accountList) {
    accounts[account.label_code] = account;
  }
  return accounts;
};

export const useApiGetAccounts = (options?: UseQueryOptionsWithoutQueryKey<Accounts>) => {
  const url = "/accounts";
  const config: InternalAxiosRequestConfig = { method: "get", url, headers: new AxiosHeaders() };
  return useQuery<Accounts, Error>({
    queryKey: [QUERY_KEY_ACCOUNT, url],
    queryFn: () => getAccountsFn(config),
    staleTime: Infinity,
    ...options
  });
};
