/**
 * Overrides @decathlon/react-table table interaction manager to provide a default config shared
 * across all tables in MyGame
 */

import TableInteractionsManager from "@decathlon/react-table/dist/components/table-interactions-manager/table-interactions-manager";
import { DEFAULT_TABLE_CONFIG } from "core/constants/table";
import { ComponentProps } from "react";
import React from "react";
// not exported by react-table
type TableInteractionsManagerProps = ComponentProps<typeof TableInteractionsManager>;
// make `toggleableColumns` optional without requiring to `defaultProps`
export type TableInteractionsManagerCustomProps = Omit<TableInteractionsManagerProps, "toggleableColumns"> & {
  toggleableColumns?: TableInteractionsManagerProps["toggleableColumns"];
};

export type TableInteractionsManagerConfig = TableInteractionsManagerProps["initialConfig"];

// Holds the table config (no need to expose this through some state, an update is not supposed to
// trigger a rerender, since in the end, this is the responsibility of the TableInteractionsContext)
let tableConfig: TableInteractionsManagerConfig = {
  ...DEFAULT_TABLE_CONFIG,
  ...JSON.parse(localStorage.getItem("tableConfig"))
};

const setDefaultTableConfig = (newTableConfig: TableInteractionsManagerConfig) => {
  tableConfig = newTableConfig;
};

const getDefaultTableConfig = () => {
  return tableConfig;
};

/**
 * Wraps a function which updates the table interactions manager to persist changes in local storage
 */
export const persistTableInteractionsManagerConfig = <
  K extends keyof TableInteractionsManagerConfig,
  F extends (value: TableInteractionsManagerConfig[K]) => void
>(
  key: K,
  patchFn: F
): F => {
  return (value => {
    // update the local storage + the default table config
    const newTableConfig = {
      ...tableConfig,
      [key]: value
    };
    setDefaultTableConfig(newTableConfig);
    localStorage.setItem("tableConfig", JSON.stringify(newTableConfig));
    patchFn(value);
  }) as F;
};

/**
 * Thin wrapper around <TableInteractionsManager /> to provide shared defaults
 */
const TableInteractionsManagerCustom: React.FC<TableInteractionsManagerCustomProps> = (
  // not very nice, but difficult to do better given the actual typings of react-table table
  // interactions manager
  { children, initialConfig = {}, onStateUpdate, toggleableColumns = [] }
) => {
  return (
    <TableInteractionsManager
      initialConfig={{ ...getDefaultTableConfig(), ...initialConfig }}
      onStateUpdate={onStateUpdate}
      toggleableColumns={toggleableColumns}
    >
      {children}
    </TableInteractionsManager>
  );
};

export default TableInteractionsManagerCustom;
