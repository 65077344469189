import { createContext, Dispatch, SetStateAction } from "react";

interface INavigationContext {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export const initialContext: INavigationContext = {
  setIsOpen: () => {
    /* empty */
  },
  isOpen: false
};

export const NavigationContext = createContext<INavigationContext>(initialContext);
NavigationContext.displayName = "NavigationContext";
