import { useQuery } from "@tanstack/react-query";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosHeaders } from "axios";
import { IAnnouncement } from "../../models/api/announcement/announcement";

export interface IAnnouncementDto {
  active: boolean;
  message: string;
  link?: string;
  type: "RELEASE" | "INCIDENT" | "INFORMATION";
}

const getAnnouncement = async (config: InternalAxiosRequestConfig): Promise<IAnnouncement> => {
  const axiosPromise: AxiosResponse<IAnnouncementDto> = await axios(config);
  return {
    active: axiosPromise.data.active,
    message: axiosPromise.data.message,
    link: axiosPromise.data.link,
    type: axiosPromise.data.type
  };
};

export const QUERY_KEY_ANNOUNCEMENT = "get-announcement";

export const useApiGetAnnouncement = (options?: UseQueryOptionsWithoutQueryKey<IAnnouncementDto>) => {
  const url = `/announcement`;
  const config: InternalAxiosRequestConfig = { method: "get", url, headers: new AxiosHeaders() };
  return useQuery<IAnnouncementDto>({
    queryKey: [QUERY_KEY_ANNOUNCEMENT],
    queryFn: () => getAnnouncement(config),
    staleTime: Infinity,
    ...options
  });
};
