import { I18n } from "react-redux-i18n";

import DateUtils from "core/utils/date";
import { FilterKey, IFilterKey, IFilters } from "core/redux/filters/reducers";
import useTemplates from "core/hooks/useTemplates";
import { Store } from "core/models/store";
import { ITeam } from "core/api/rest/queries/select-teams";
import { Visibility } from "core/models/template";
import { ITemplate } from "core/utils/templates";
import { ISport } from "core/api/rest/queries/select-sport";
import useObjFromList from "./useObjFromList";
import { StoresGroup } from "../models/stores-group";
import { useApiGetStoresGroup } from "../api/stores-group/useApiGetStoresGroup";
import { useApiGetStores } from "../api/store/useApiGetStores";
import { useApiGetSportsGroup } from "../api/sports-group/useApiGetSportsGroup";
import { ISportsGroup } from "core/models/api/sports-group/sports-group-piloting";
import { Aggregate } from "../models/api/aggregate/aggregate";
import { getTranslatedAggregateLabel } from "../utils/aggregates";

export interface ISources {
  teams?: Record<string, ITeam>;
  templates?: Record<string, ITemplate>;
  stores?: Record<string, Store>;
  sports?: Record<string, ISport>;
  sportsGroups?: Record<string, ISportsGroup>;
  storesGroups?: Record<string, StoresGroup>;
}

export const useSportsGroupsObj = (): Record<string, ISportsGroup> => {
  const { data: dataSportsGroup } = useApiGetSportsGroup();
  return useObjFromList(dataSportsGroup, "id");
};

export const useCurrentSportsGroupsObj = (): Record<string, ISportsGroup> => {
  const { data: dataSportsGroup } = useApiGetSportsGroup();
  return useObjFromList(dataSportsGroup, "id");
};

export const getSportsGroupsLabel = (sportsGroupsId: number, sportsGroups?: Record<string, ISportsGroup>): string => {
  const sportsGroupValue = sportsGroups && sportsGroups[sportsGroupsId];
  /**
   * if the sports group filter is not included in list of sports groups,
   * it means that it's the all favorites sports group selected
   */
  return sportsGroupValue ? sportsGroupValue.label : I18n.t("settings.sport_groups.all_sports");
};

export const getSportLabel = (sportId: number, sports?: Record<string, ISport>, includeId = false): string => {
  const sportValue = sports && sports[sportId];
  const sportDepId = sportValue && sportValue.department_id;
  return sportValue ? `${includeId ? `${sportDepId}-` : ""}${sportValue.label}` : sportId?.toString();
};

export const getWeekLabel = (week: number, year: number): string => {
  return DateUtils.getWeekLabel(year, week);
};

export const getTeamLabel = (teamId: number, teams?: Record<string, ITeam>): string => {
  const teamValue = teams && teams[teamId];
  return teamValue ? teamValue.label : teamId?.toString();
};

/**
 *  If the cached stores data is available, we use it immediately.
 *  Otherwise, we fetch the stores data using the useApiStores.
 */
export const useStoresObj = (): Record<string, Store> => {
  const { data: stores } = useApiGetStores();

  return useObjFromList(stores, "id");
};

export const getStoreLabel = (storeId: number, stores?: Record<string, Store>, includeId = false): string => {
  const storeValue = stores && stores[storeId];
  return storeValue ? `${includeId ? `${storeId}-` : ""}${storeValue.label}` : storeId?.toString();
};

export const useTemplatesObj = (): Record<string, ITemplate> => {
  const templates = useTemplates(Visibility.IsVisible);
  return useObjFromList(templates, "id");
};

export const getTemplateLabel = (templateId: number, templates?: Record<string, ITemplate>): string => {
  const templateValue = templates && templates[templateId];
  return templateValue ? templateValue.label : templateId?.toString();
};

export const getAggregateLabel = (aggregateId: number, aggregates: Aggregate): string => {
  const aggregateSelected = [...(aggregates.calendar ?? []), ...(aggregates.load_aggregate ?? [])].find(
    agg => agg.id === aggregateId
  );

  return aggregateSelected?.label_code
    ? getTranslatedAggregateLabel(aggregateSelected.label_code)
    : "Unknown Aggregate";
};

export const useStoresGroupsObj = (): Record<string, StoresGroup> => {
  const { data: storesGroups } = useApiGetStoresGroup();
  return useObjFromList(storesGroups, "id");
};

export const getStoresGroupsLabel = (storesGroupsId: number, storesGroups?: Record<string, StoresGroup>): string => {
  const storesGroupValue = storesGroups && storesGroups[storesGroupsId];
  /**
   * if the stores group filter is not included in list of stores groups,
   * it means that it's the all favorites stores group selected
   */
  return storesGroupValue ? storesGroupValue.label : I18n.t("settings.store_groups.all_stores");
};

export const getFilterLabel = (
  filterKey: string,
  filters: Partial<IFilters>,
  sources?: ISources,
  aggregates?: Aggregate,
  includeId = false
): string | null => {
  switch (filterKey) {
    case FilterKey.TEMPLATE:
      return getTemplateLabel(filters[filterKey], sources.templates);
    case FilterKey.STORE_ID:
      return getStoreLabel(filters[filterKey], sources.stores, includeId);
    case FilterKey.TEAM:
      return getTeamLabel(filters[filterKey], sources.teams);
    case FilterKey.SPORT:
      return getSportLabel(filters[filterKey], sources.sports, includeId);
    case FilterKey.SPORTS_GROUP:
      return getSportsGroupsLabel(filters[filterKey], sources.sportsGroups);
    case FilterKey.WEEK:
      return !filters[FilterKey.AGGREGATE] ? getWeekLabel(filters[filterKey], filters[FilterKey.YEAR] as number) : null;
    case FilterKey.AGGREGATE:
      return getAggregateLabel(filters[filterKey], aggregates) || null;
    case FilterKey.YEAR:
      return !filters[FilterKey.WEEK] ? filters[filterKey]?.toString() || null : null;
    case FilterKey.STORES_GROUP:
      return getStoresGroupsLabel(filters[filterKey], sources.storesGroups);
    default:
      return filters[filterKey]?.toString();
  }
};

export const formatObject = (obj: Record<string, string>, separator = "_"): string => {
  return Object.keys(obj).reduce((format, key) => {
    if (format) {
      return `${format}${separator}${obj[key]}`;
    }
    return obj[key];
  }, "");
};

const buildFilterLabels = (
  filters: Partial<IFilters>,
  sources?: ISources,
  aggregates?: Aggregate,
  includeId = false
): Record<string, string> => {
  const filterKeys = Object.keys(filters) as IFilterKey[];
  return filterKeys.reduce<Record<string, string>>((labels, filterKey) => {
    const filterLabel = getFilterLabel(filterKey, filters, sources, aggregates, includeId);
    if (filterLabel !== null) {
      labels[filterKey] = filterLabel;
    }
    return labels;
  }, {});
};

export default buildFilterLabels;
