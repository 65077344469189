import { jwtDecode } from "jwt-decode";

import { IAccessToken } from "core/models/access-token";
import { IAllJobsViewsDto } from "../api/menu/useApiMenuGetAllJobsViews";
import { navigation_menu } from "../../lang/en.json";

const issList = ["idpdecathlon.preprod.org", "idpdecathlon"];

export const validateUserAccess = (token?: string): boolean => {
  if (!token) {
    return false;
  }
  const decodedAccessToken = jwtDecode<IAccessToken>(token);
  return !(decodedAccessToken == null || !decodedAccessToken.sub || !issList.includes(decodedAccessToken.iss));
};
type NavigationMenu = typeof navigation_menu;

export const mapRoleIDToRoleName = (
  roleId: number | undefined,
  roleMapping: IAllJobsViewsDto[],
  translationMapping: NavigationMenu
): RoleName => {
  if (!roleId) return "NOT-SET";
  const label = roleMapping.find(({ id }) => id === roleId)?.label;
  if (!label) throw Error("Role not found for id " + roleId);
  return translationMapping[label.toUpperCase()];
};

export type RoleName = string;
