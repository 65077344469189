import { CircularProgress, IconButton, Select as MUISelect } from "@mui/material";
import FilterConstants from "core/constants/filter-constants";
import { caseInsensitiveTrimFilterWithZeroIndexOrIncluding } from "core/utils/filters";
import classnames from "classnames";

import { ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { I18n } from "react-redux-i18n";
import { IDataSource } from "./index";
import CustomIcon from "../icons/customIcon";
import Search, { IData } from "./search";
import SelectMenu from "./select-menu";
import { useMeasure } from "@uidotdev/usehooks";
import React from "react";
export type Value = number | string;

interface ISelectContext {
  data: IData;
  selectedValue: Value | Value[];
  isMultiple?: boolean;
  isVirtualized?: boolean;
  virtualizedItemsToDisplay?: number;
  disabledItemsIds?: Value[];
  handleChange?: (value: Value | Value[]) => void;
  toggleItem?: (value: Value) => void;
  selectItemLabelFormat?: (itemData: IDataSource) => string;
}

export const SelectContext = createContext<ISelectContext>({ selectedValue: null, data: null });

interface SearchConfig {
  data: IData;
  isSearchable: boolean;
  isMultiple: boolean;
  selectedValue: Value | Value[];
  selectItemLabelFormat?: (itemData: IDataSource) => string;
}

// Convoluted, but since `strictNullChecks` are disabled we cannot rely on discriminated union
// types.
interface SearchUtilsNonSearchable {
  isSearchable: false;
  filteredData: IData;
}
interface SearchUtilsSearchable {
  isSearchable: true;
  filteredData: IData;
  nbItems: number;
  nbFilteredItems: number;
  setSearchedText: (text: string) => void;
  searchedText: string;
  candidates: (string | number)[];
}

type SearchUtils = SearchUtilsNonSearchable | SearchUtilsSearchable;

// mimic discriminated union types
const isSearchUtilsSearchable = (utils: SearchUtils): utils is SearchUtilsSearchable => utils.isSearchable;
/**
 * Provides utilities to connect the <Search /> field to the select box
 *
 * Since hook cannot be called conditionally, we return early if `isSearchable` is set to `false`.
 */
const useSearch = ({ data, isSearchable, selectItemLabelFormat }: SearchConfig): SearchUtils => {
  // will not be valued if the select is not searchable
  const [searchedText, setSearchedText] = useState("");

  if (!isSearchable) {
    return {
      isSearchable: false,
      filteredData: data
    };
  }

  const filteredData: IData = searchedText
    ? Object.keys(data).reduce((acc, groupId) => {
        const dataGroup = data[groupId];
        acc[groupId] = {
          ...dataGroup,
          data: data[groupId].data.filter((groupData: IDataSource) =>
            caseInsensitiveTrimFilterWithZeroIndexOrIncluding(searchedText, selectItemLabelFormat(groupData))
          )
        };
        return acc;
      }, {})
    : data;

  const nbItems = Object.keys(data).reduce((acc, groupId) => {
    acc += data[groupId].data.length;
    return acc;
  }, 0);

  const nbFilteredItems = Object.keys(filteredData).reduce((acc, groupId) => {
    acc += filteredData[groupId].data.length;
    return acc;
  }, 0);

  const candidates: Array<number | string | null> = [];

  for (const group of Object.values(filteredData)) {
    for (const item of group.data) {
      candidates.push(item.id);
    }
  }

  return { isSearchable, nbItems, nbFilteredItems, filteredData, setSearchedText, searchedText, candidates };
};
export interface IProps {
  data: IData;
  id?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  isLoading?: boolean;
  hasBottomLine?: boolean;
  canBeReset?: boolean;
  /** controlled value props */
  value?: Value | Value[];
  /** controlled onchange props */
  onChange?: (selectedValue: Value | Value[]) => void;
  /** renderer to display selected(s) item(s) when the popover is closed */
  renderValue?: (selectedValue: Value | Value[]) => React.ReactNode;
  /** renderer to display action elements above menu items */
  renderActions?: () => React.ReactNode;
  /** format the item label displayed inside the select */
  selectItemLabelFormat?: (itemData: IDataSource) => string;
  /** allow the multi selection */
  isMultiple?: boolean;
  maxValues?: number;
  /** allow the virtualization of each menu group */
  isVirtualized?: boolean;
  /** disable the select */
  isDisabled?: boolean;
  /** number of items to display, if items > virtualizedItemsToDisplay, there will be a scrollbar  */
  virtualizedItemsToDisplay?: number;
  /** display an input to filter data */
  isSearchable?: boolean;
  disabledItemsIds?: Value[];
  initialIsOpen?: boolean;
  isSelectViewIcon?: boolean;
  tooltipTitle?: string;
}
const defaultListValue = [];

const EmptyIcon = () => null;

const Select = (props: IProps) => {
  const {
    id,
    data,
    value,
    disabledItemsIds = [],
    className,
    label,
    placeholder,
    isLoading,
    canBeReset,
    isSearchable,
    searchPlaceholder,
    isMultiple,
    maxValues,
    isVirtualized,
    isDisabled: isPropsDisabled,
    initialIsOpen = false,
    virtualizedItemsToDisplay = FilterConstants.FILTER_AUTOCOMPLETE_MAX_RESULTS,
    onChange,
    renderValue = (selected: string[]) => {
      return isMultiple ? selected.join(", ") : selected;
    },
    renderActions,
    selectItemLabelFormat,
    isSelectViewIcon,
    tooltipTitle
  } = props;

  const initialValue = !value && isMultiple ? defaultListValue : value;
  const [selectedValue, setSelectedValue] = useState<Value | Value[]>(initialValue);
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const anchorEl = useRef(null);
  const [ref, { width }] = useMeasure();

  const hasSelectedValue = useMemo(() => {
    return isMultiple ? (selectedValue as Value[]).length : selectedValue != null;
  }, [selectedValue, isMultiple]);

  const hasData = useMemo(() => {
    return Object.keys(data).some(groupId => data?.[groupId]?.data?.length);
  }, [data]);

  const isDisabled = !hasData || isLoading || isPropsDisabled;

  useEffect(() => {
    if (value !== selectedValue && value !== undefined) {
      const initialValue = !value && isMultiple ? defaultListValue : value;
      setSelectedValue(initialValue);
    }
  }, [isMultiple, value, selectedValue]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    if (isOpen || !isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const globalOpenMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDisabled && event.target === event.currentTarget) {
      setIsOpen(true);
    }
  };

  const handleChange = useCallback(
    (newSelectedValue: Value | Value[]) => {
      if (isMultiple && maxValues !== undefined && (newSelectedValue as Value[]).length > maxValues) {
        return;
      }

      setSelectedValue(newSelectedValue);
      if (onChange) {
        onChange(newSelectedValue);
      }
      if (!isMultiple) {
        handleClose();
      }
    },
    [isMultiple, maxValues, onChange]
  );

  const toggleItem = (id: Value) => {
    let newSelectedValue: Value | Value[];
    if (isMultiple) {
      newSelectedValue = (selectedValue as Value[]).filter(selectedId => selectedId !== id);
      if (newSelectedValue.length === (selectedValue as Value[]).length) {
        newSelectedValue.push(id);
      }
    } else {
      newSelectedValue = id;
    }
    handleChange(newSelectedValue);
  };

  const clear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    handleChange(isMultiple ? [] : null);
  };

  const searchUtils = useSearch({
    data,
    isMultiple,
    isSearchable,
    selectedValue,
    selectItemLabelFormat
  });

  let selectMenu: ReactNode;

  if (isSearchUtilsSearchable(searchUtils)) {
    const { candidates, nbItems, nbFilteredItems, setSearchedText, searchedText } = searchUtils;
    /**
     * Select the items matching the possible searched text
     *
     * If `isMultiple` is set to `true`, all items matching the current text will be selected.
     * Otherwise, only the first item will be selected.
     *
     * Not supposed to be called if `isSearchable` is set to `false`
     */
    const validateSearch = () => {
      if (!candidates || candidates.length === 0) return;
      if (isMultiple) {
        handleChange([
          ...(selectedValue as number[]),
          ...candidates.filter(candidate => !(selectedValue as Value[]).includes(candidate))
        ]);
      } else {
        // the first value of the active selection will be picked
        const [firstCandidate] = candidates;
        if (firstCandidate && firstCandidate !== selectedValue) {
          handleChange(firstCandidate);
        }
      }
    };

    selectMenu = (
      <Search
        id={id ? `search-${id}` : "search"}
        placeholder={searchPlaceholder}
        searchedText={searchedText}
        nbItems={nbItems}
        nbFilteredItems={nbFilteredItems}
        setSearchedText={setSearchedText}
        validateSearch={validateSearch}
      >
        <SelectMenu renderActions={renderActions} />
      </Search>
    );
  } else {
    selectMenu = <SelectMenu renderActions={renderActions} />;
  }

  const selectContextValue = useMemo(() => {
    return {
      selectedValue,
      disabledItemsIds,
      isMultiple,
      isVirtualized,
      virtualizedItemsToDisplay,
      handleChange,
      toggleItem,
      selectItemLabelFormat,
      data: searchUtils.filteredData
    };
  }, [
    selectedValue,
    disabledItemsIds,
    isMultiple,
    isVirtualized,
    virtualizedItemsToDisplay,
    handleChange,
    toggleItem,
    selectItemLabelFormat,
    searchUtils.filteredData
  ]);

  return (
    <div
      ref={ref}
      id={id}
      className={classnames(`select-container ${tooltipTitle ? "mg-action-button__tooltip" : ""}`, className, {
        disabled: isDisabled
      })}
      data-testid={`select-container-${id}`}
      data-tooltip={tooltipTitle}
      data-position="bottom"
    >
      {label ? (
        <div className="select-label">
          <label htmlFor={`select-${id}`}> {label}</label>
        </div>
      ) : null}

      <div
        data-testid={`select-field_${id}`}
        id={`select-field_${id}`}
        className="select-field"
        role="button"
        tabIndex={-1}
        onClick={globalOpenMenu}
        ref={anchorEl}
      >
        {!hasSelectedValue && placeholder ? (
          <div className="select-placeholder" onClick={toggleMenu}>
            {isLoading ? I18n.t("loading") : placeholder}
          </div>
        ) : null}
        {isSelectViewIcon ? (
          <div className="select-view-icon">
            <span className="material-icons-outlined notranslate">visibility</span>
          </div>
        ) : (
          ""
        )}
        <SelectContext.Provider value={selectContextValue}>
          <MUISelect
            variant="standard"
            id={id ? `select-` : null}
            data-testid="select-content"
            className={classnames("select", {
              empty: !hasSelectedValue
            })}
            classes={{ select: "select__menu-select" }}
            multiple={isMultiple}
            value={selectedValue || ""}
            open={isOpen}
            disabled={isDisabled}
            renderValue={renderValue}
            IconComponent={EmptyIcon}
            MenuProps={{
              disablePortal: true,
              anchorEl: anchorEl.current,
              classes: { list: "select__menu-list" },
              PopoverClasses: { root: "select__popover", paper: "select__paper" },
              PaperProps: { style: { width: width } },
              elevation: 2,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              }
            }}
            onClick={toggleMenu}
          >
            {selectMenu}
          </MUISelect>
        </SelectContext.Provider>

        {isLoading ? (
          <div className="select-field__loading">
            <CircularProgress size={25} />
          </div>
        ) : (
          <div data-testid="select-field-button-container" className="select-field__buttons-container">
            {canBeReset && hasSelectedValue ? (
              <IconButton data-testid="select-field-clear-button" onClick={clear} size="small" disabled={isDisabled}>
                <span className="material-icons-outlined notranslate select-field__button__icon clear notranslate">
                  backspace
                </span>
              </IconButton>
            ) : null}
            <IconButton
              className="select-field__button"
              data-testid="select-field-button"
              size="small"
              onClick={toggleMenu}
              disabled={isDisabled}
            >
              <CustomIcon name={isOpen ? "expand_less" : "expand_more"} className="select-field__button__icon" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
