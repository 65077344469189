import * as actionTypes from "./actionTypes";
import { PartialFilters } from "../filters/reducers";

export interface SetToken {
  type: actionTypes.SET_TOKEN;
  token: string;
}

export const setToken = (token: string): SetToken => ({
  type: actionTypes.SET_TOKEN,
  token
});

export interface SetUserPreferencesFilters {
  type: actionTypes.SET_USER_PREFERENCES_FILTERS;
  filters: PartialFilters;
}

export type UserAction = SetToken | SetUserPreferencesFilters;
