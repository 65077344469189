import { FC, useContext, useMemo } from "react";
import { IContentCellProps } from "@decathlon/react-table";
import { DumbSelectStore, IProps } from "components/common/filters/select-store";
import { UpwardForecastExceptionManagerContext } from "../upward-forecast-exception-context";
import { useFilters } from "../../../../common/filters3/filters/hooks/useFilters";
import { GenericFilters } from "../../../../common/filters3/filters/definitions";
import { useApiGetStores } from "../../../../../core/api/store/useApiGetStores";
import { useApiGetForecastByCountryCode } from "../../../../../core/api/upward-forecast/useApiGetForecastByCountryCode";
import { JSX } from "react";
export interface IActionsCellProps extends IContentCellProps {
  exceptionId: number;
  storeLabel: string;
}

type ISelectStoreProps = { countryId: string };

export const SelectStore = (props: IProps & ISelectStoreProps): JSX.Element => {
  const { data: stores, isLoading: loading } = useApiGetStores();
  const countryStores = stores ? stores.filter(store => store.country_id === props.countryId) : [];
  return <DumbSelectStore data={countryStores} value={props.storeId} isLoading={loading} {...props} />;
};

export const StoreCell: FC<IActionsCellProps> = ({ exceptionId, storeLabel }) => {
  const { newExceptionStoreId, updateException } = useContext(UpwardForecastExceptionManagerContext);
  const {
    appliedFilters: { country }
  } = useFilters<GenericFilters>();
  const { data: dataForecastByCountryCode } = useApiGetForecastByCountryCode({ countryCode: country });
  const disabledItemsIds = useMemo(() => {
    return dataForecastByCountryCode?.exception_stores.map(exceptionStore => exceptionStore.store_id);
  }, [dataForecastByCountryCode?.exception_stores]);

  return (
    <div>
      {exceptionId ? (
        storeLabel
      ) : (
        <SelectStore
          id="user-preferences_select_store"
          storeId={newExceptionStoreId}
          countryId={country}
          onChange={updateException}
          hasBottomLine
          disabledItemsIds={disabledItemsIds}
        />
      )}
    </div>
  );
};

export default StoreCell;
