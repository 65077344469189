import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { DATADOG_SERVICE, DATADOG_SITE, DEVELOP, ENV_PREPROD, ENV_PROD, PACKAGE_VERSION } from "../constants/datadog";
import { UserInfo } from "./useAuthUserInfo";

export const useDatadog = (user: UserInfo) => {
  useEffect(() => {
    const shouldInitializeDatadog = [ENV_PROD, ENV_PREPROD, DEVELOP].includes(import.meta.env.REACT_APP_ENV);
    if (user && shouldInitializeDatadog) {
      const { REACT_APP_ENV, REACT_APP_DATADOG_APP_ID, REACT_APP_DATADOG_CLIENT_TOKEN } = import.meta.env;
      datadogRum.init({
        applicationId: REACT_APP_DATADOG_APP_ID,
        clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
        site: DATADOG_SITE,
        service: DATADOG_SERVICE,
        version: PACKAGE_VERSION,
        env: REACT_APP_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
      });
      datadogRum.startSessionReplayRecording();

      datadogLogs.init({
        clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
        site: DATADOG_SITE,
        service: DATADOG_SERVICE,
        env: REACT_APP_ENV,
        version: PACKAGE_VERSION,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
      });
      datadogRum.setUser({ id: user.uid });
      datadogRum.setUserProperty("sitename", user.sitename ?? "not-available");
      datadogRum.setUserProperty("job_name", user.jobname ?? "not-available");
      datadogLogs.setUser({ id: user.uid, job_name: user.jobname ?? "not-available" });
    }
  }, [user]);
};
