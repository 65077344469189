import DateUtils from "core/utils/date";
import { AggLevelType, DataTypesType, TimeUnitType } from "./reporting-extracts";

export default class FilterConstants {
  public static FILTER_AUTOCOMPLETE_MAX_RESULTS = 8;
}

export type GranularityLevel = {
  id: string;
  label: string;
};

export interface IFilterDateRangeYearConstant {
  from: number;
  to: number;
}

export const FILTER_DATE_RANGE_YEAR: IFilterDateRangeYearConstant = {
  from: new Date().getFullYear() - 1,
  to: new Date().getFullYear() + 1
};

export const getFilterYearOrCurrentYear = (
  dateRange: IFilterDateRangeYearConstant,
  filterYear: number,
  firstYear: number
): number => {
  return DateUtils.isInFilterRange(dateRange, filterYear) ? filterYear : firstYear;
};

export const STORE = "store";
export const SPORT = "sport";
export const TEAM = "team";
export const TEMPLATE = "template";
export const YEAR = "year";
export const MONTH = "month";
export const AGGREGATE = "aggregate";
export const WEEK = "week";

export const pilotingDaysUrl = "/piloting/days";
export const pilotingManagerByMonth = "/piloting/manager-by-month";

export const SELECT_DATA_TYPES = [
  { id: DataTypesType.PILOTED, label: "reporting.piloted" },
  { id: DataTypesType.REALIZED, label: "reporting.realized" }
];

export const SELECT_DATA_TYPES_MONTHLY = [
  { id: DataTypesType.PILOTED, label: "reporting.piloted" },
  { id: DataTypesType.REALIZED, label: "reporting.realized" },
  {
    id: DataTypesType.DIRECTOR,
    label: "reporting.director"
  }
];

export const SELECT_TYPE_UNIT = [
  { id: TimeUnitType.YEARLY, label: "reporting.yearly" },
  { id: TimeUnitType.WEEKLY, label: "reporting.weekly" }
];

export const GRANULARITY_LEVEL: GranularityLevel[] = [
  { id: AggLevelType.STORE, label: "reporting.store" },
  { id: AggLevelType.TEAM, label: "reporting.team" },
  { id: AggLevelType.SPORT, label: "reporting.sport" },
  { id: AggLevelType.COUNTRY, label: "reporting.country" }
];
