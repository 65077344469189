import { ITableInteractionManagerState } from "@decathlon/react-table";

export interface VirtualizedTableSize {
  height?: number;
  width?: number;
}

interface ICellSize {
  small: number;
  medium: number;
  large: number;
}

// Named used to represent the total of an indicator
export const TOTAL = "TOTAL";

export const PREVIOUS_AVAILABLE_YEARS = 2; // Datepicker 2 years before current year 2022 (ex 2020/2021/2022/2023)
export const NEXT_AVAILABLE_YEARS = 1;

export const NULL_INITIAL_VALUE = "-";

export const getCustomRowHeight = (): ICellSize => {
  // We set specific values for tests to prevent to update every tests each time we have to modify row height values
  if (import.meta.env.NODE_ENV === "test") {
    return {
      small: 60,
      medium: 80,
      large: 100
    };
  }
  return {
    small: 25,
    medium: 45,
    large: 65
  };
};

export const getCustomCellWidth = (): ICellSize => {
  // We set specific values for tests to prevent to update every tests each time we have to modify row height values
  if (import.meta.env.NODE_ENV === "test") {
    return {
      small: 90,
      medium: 160,
      large: 230
    };
  }
  return {
    small: 110,
    medium: 160,
    large: 210
  };
};

export const CUSTOM_ROW_HEIGHT_OPTIONS = getCustomRowHeight();

export const CUSTOM_CELL_WIDTH_OPTIONS = getCustomCellWidth();

export const getCustomTableDefaultConfig = (): Partial<ITableInteractionManagerState> => {
  if (import.meta.env.NODE_ENV === "test") {
    return {
      rowHeight: { value: CUSTOM_ROW_HEIGHT_OPTIONS.small, size: "small" },
      cellWidth: { value: CUSTOM_CELL_WIDTH_OPTIONS.small, size: "small" }
    };
  }
  return {
    rowHeight: { value: CUSTOM_ROW_HEIGHT_OPTIONS.small, size: "small" },
    cellWidth: { value: CUSTOM_CELL_WIDTH_OPTIONS.small, size: "small" }
  };
};

export const DEFAULT_TABLE_CONFIG = getCustomTableDefaultConfig();

export const getTableSize = (): VirtualizedTableSize => {
  if (import.meta.env.NODE_ENV === "test") {
    return {
      height: 771,
      width: 1254
    };
  }
  return {};
};
