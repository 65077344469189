import * as React from "react";
import { ITableProps } from "@decathlon/react-table";

import { IDataCoordinates } from "core/utils/cells";
import getTableFromCex from "core/api/rest/middlewares/sport-piloting/upward-forecast/dataTable";
import { IUpwardForecast } from "core/api/rest/queries/upward-forecast";
import { JSX } from "react";
export interface IChildrenProps {
  /** the table props */
  table?: Pick<ITableProps<IDataCoordinates>, "id" | "rows" | "columns">;
}

interface IProps {
  data: IUpwardForecast;
  setIsSaveInProgress: (isSaveInProgress: boolean) => void;
  children: (props: IChildrenProps) => JSX.Element | JSX.Element[];
}

const UpwardForecastTableWrapper: React.FunctionComponent<IProps> = ({ data, children, setIsSaveInProgress }) => {
  const [table, setTable] = React.useState(null);

  React.useEffect(() => {
    if (data) {
      setTable(getTableFromCex(data));
    }
  }, [data, setIsSaveInProgress]);

  return (
    <React.Fragment>
      {children({
        table
      })}
    </React.Fragment>
  );
};

export default UpwardForecastTableWrapper;
