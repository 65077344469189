import classNames from "classnames";
import { CellWithIcon, ICellWithIconProps, ICell as ITableCell, IRow } from "@decathlon/react-table";

import CellWithItems, { ICellItem } from "components/common/table/table-interactions-manager/cell-with-items";
import SortColumnsButton, {
  ISortColumnsButtonProps
} from "components/common/table/table-interactions-manager/sort-columns-button";
import HoursLockedIcon, { IProps as IHoursLockedIconProps } from "pages/hours/shared/components/hours-locked-icon";
import CountryComparisonIcon from "components/common/icons/country-comparison-icon";
import StoreEventIcon from "components/common/icons/store-event-icon";
import { IMask } from "core/api/rest/queries";
import { IAccounts, ICell } from "core/utils/accounts";
import { rowsGetterDefaultOptions, IRowsGetterOptions } from "./rows";
import { ACCOUNT_HEADER_ID, IAbstractHeader } from "./headers";
import CollapseUncollapseCell from "components/common/table/table-interactions-manager/collapse-uncollapse-cell";
import { getCssConfiguration, isIndicatorProgression } from "./cssConfiguration";
import { StoreEventById } from "core/models/store-events";

export const STORE_CASH_HOURS = "STORE_CASH_HOURS";
export const DEPARTMENT_HOURS = "DEPARTMENT_HOURS";
export interface IDataCoordinates {
  // the id/key of the rows, here TURNOVER_N_1 for example
  rowId: string;
  // the id/key of the columns, here 2019W01 for example
  columnId: string;
}

export const getCountryComparisonIcon = (id: string): ICellItem => {
  return {
    id: `${id}_comparisonIcon`,
    component: CountryComparisonIcon
  };
};
export const getStoreEventIcon = (
  id: string,
  isDayEvent: boolean,
  currentEventListInWeek: StoreEventById[]
): ICellItem => {
  return {
    id: `${id}_storeEventIcon`,
    component: StoreEventIcon,
    componentProps: {
      isDayEvent: isDayEvent,
      currentEventListInWeek: currentEventListInWeek
    }
  };
};
export const getLockedIcon = (id: string, componentProps: IHoursLockedIconProps): ICellItem<IHoursLockedIconProps> => {
  return {
    id: `${id}_lockedIcon`,
    component: HoursLockedIcon,
    componentProps
  };
};

export const getSortAction = (
  id: string,
  componentProps: ISortColumnsButtonProps
): ICellItem<ISortColumnsButtonProps> => {
  return {
    id: `${id}_sortAction`,
    component: SortColumnsButton,
    componentProps
  };
};

/**
 * Used to format value with the account mask
 * @param value
 * @param mask
 * @param locale
 * @return value formatted
 */
export const formatValue = (
  value: number | string | null | undefined,
  mask: IMask,
  locale = window.navigator.language
): string => {
  if (value == null || value == undefined) {
    return "-";
  }

  if (typeof value === "string" || !mask) {
    return value.toString();
  }

  const formattedValue: string = new Intl.NumberFormat(locale, {
    style: mask.is_percentage ? "percent" : undefined,
    maximumFractionDigits: mask.decimals,
    minimumFractionDigits: mask.decimals
  }).format(value);
  return Number.isNaN(formattedValue) ? value?.toString() : formattedValue;
};

/**
 * Used to check if new value is conform with the account mask
 * @param mask and number
 * @return boolean
 */
export const validateValue =
  (mask: IMask) =>
  (value: number): boolean =>
    !(!mask.is_negative && value < 0);

export const isNegative = (cellValue: string | number) => parseFloat(cellValue?.toString().replace(",", ".")) < 0;
export const isPositive = (cellValue: string | number) => parseFloat(cellValue?.toString().replace(",", ".")) > 0;

/**
 * Used to give dynamic classnames to cells
 * @param tableCell
 */
export const getClassName = ({ value, className, cellContentProps }: ITableCell): string => {
  let cellValue: string | number = value;

  // @ts-ignore cellContentProps is an object type
  if (cellContentProps?.value) {
    // @ts-ignore cellContentProps is an object type
    cellValue = cellContentProps.value;
  }
  cellValue = cellValue || 0;
  return classNames(className, { negative: isNegative(cellValue), positive: isPositive(cellValue) });
};

/**
 * Get the sub item cells from rows parent accounts
 */
export const getSubItemCells = (
  subItemsAccountIds: string[],
  accounts: IAccounts,
  headerIds: string[],
  options: Pick<
    IRowsGetterOptions,
    "getMainColumnCell" | "getEditableCell" | "getCustomCell" | "mainColumnId"
  > = rowsGetterDefaultOptions
): IRow[] => {
  const getterOptions = { ...rowsGetterDefaultOptions, ...options };
  const { getMainColumnCell, getEditableCell, getCustomCell, mainColumnId } = getterOptions;
  if (subItemsAccountIds) {
    return subItemsAccountIds.reduce((result, accountId) => {
      const account = accounts[accountId];
      if (account) {
        const { cells, label, mask: rowMask } = account;
        const subItemId = "sub-item_cell";
        const cellSubItemId = `cell_${subItemId}`;
        const cellSubItem = {
          id: `${subItemId}_${accountId}`,
          cells: headerIds.map((headerId, headerIndex) => {
            const currentCell = cells[headerId];
            const mask = currentCell?.mask || rowMask;
            const getCustomClassName = isIndicatorProgression(accountId) ? getClassName : undefined;
            const accountLabel = (currentCell?.value && formatValue(currentCell.value, mask)) || label;
            if (headerId === mainColumnId) {
              return {
                ...getMainColumnCell({
                  accounts,
                  accountId,
                  accountLabel,
                  accountLabelId: `${cellSubItemId}_${accountId}`,
                  isSubItem: true
                }),
                className: getCssConfiguration(accountId, headerId)
              };
            }
            const formattedValue = formatValue(currentCell?.value, mask);
            if (getCustomCell) {
              const customCell = getCustomCell({ rowId: accountId, columnId: headerId, currentCell, mask, accounts });
              if (customCell) {
                customCell.getClassName = getCustomClassName;
                customCell.className = getCssConfiguration(accountId, headerId);
                return customCell;
              }
            }
            // Fix MG-3565
            if (currentCell?.is_editable) {
              const editableCell = getEditableCell(currentCell, mask, accountId, headerId, subItemId);
              editableCell.getClassName = getCustomClassName;
              editableCell.className = getCssConfiguration(accountId, headerId);
              return editableCell;
            }

            return {
              id: `${subItemId}_${headerId}_${headerIndex}`,
              value: formattedValue,
              getClassName: getCustomClassName,
              className: getCssConfiguration(accountId, headerId)
            };
          })
        };
        result.push(cellSubItem);
        return result;
      }
      return result;
    }, []);
  }
  return [];
};

export const getCustomCell = (
  id: string,
  cell: ICell,
  mask: IMask,
  onClick: (this: void) => void,
  iconName = "edit",
  tooltipTitle?: string
): ITableCell => {
  const value = formatValue(cell.value, mask);

  const cellContentProps: ICellWithIconProps = {
    id,
    iconName,
    value,
    tooltipTitle,
    onClick
  };

  return {
    id,
    cellContent: CellWithIcon,
    cellContentProps
  };
};

/**
 * Build the header cell for a column with indicator names.
 *
 * Includes the icon to collapse/uncollapse all child rows.
 */
export const getAccountHeaderCell = (currentHeader: IAbstractHeader) => ({
  id: ACCOUNT_HEADER_ID,
  cellContent: CellWithItems,
  cellContentProps: {
    value: currentHeader.value?.toString(),
    items: [{ component: CollapseUncollapseCell, componentProps: {} }]
  }
});
