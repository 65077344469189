import { ReactElement } from "react";
import { I18n } from "react-redux-i18n";

type IModal = {
  icon?: string;
  content: string | ReactElement;
  handleClickCancel?: () => void;
  handleClickContinue: () => void;
  cancelMessage?: string | ReactElement;
  saveMessage?: string;
  textContentCenter?: boolean;
  isIcon?: boolean;
  header?: ReactElement;
  isFooter?: boolean;
  footerBtnCenter?: boolean;
};

const Modal = ({
  content,
  handleClickCancel,
  handleClickContinue,
  cancelMessage = I18n.t("cancel"),
  saveMessage = I18n.t("confirmation.continue"),
  textContentCenter,
  icon = "info",
  isIcon = true,
  header,
  isFooter = true,
  footerBtnCenter
}: IModal) => {
  return (
    <div data-testid="mg-modal-overlay" className="mg-modal-overlay">
      <div className="mg-modal">
        {header}
        <div className="mg-modal__content">
          {isIcon && (
            <div className="mg-modal__content__icon">
              <span className="material-icons-outlined notranslate">{icon}</span>
            </div>
          )}
          <div className={`mg-modal__content__text ${textContentCenter ? "text-center" : ""}`}>{content}</div>
        </div>
        {isFooter && (
          <div className={`mg-modal__footer ${footerBtnCenter ? "modal-footer-center" : ""}`}>
            {handleClickCancel && (
              <button
                data-testid="modal-cancel-button"
                className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
                onClick={handleClickCancel}
              >
                {cancelMessage}
              </button>
            )}
            <button
              data-testid="modal-continue-button"
              className="vtmn-btn vtmn-btn_size--small"
              onClick={handleClickContinue}
            >
              {saveMessage}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
