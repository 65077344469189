import update from "immutability-helper";
import {
  ICellPath,
  getCell,
  setCell,
  ICell as ITableCell,
  ICellCoordinates,
  EDITED_CELL_CLASSNAME,
  IRow as ITableRow
} from "@decathlon/react-table";
import EditableCell from "components/common/cells/editable-cell";

import { formatValue, getClassName, IDataCoordinates } from "core/utils/cells";
import { getEditableCell } from "core/utils/rows";
import { IMask } from "core/api/rest/queries";
import { IPathCache, IRows, IPathRow, ICache, IComputedCells, IRow } from "./index";

export const getCellPath = (
  rows: IRows<IPathRow>,
  rowId: string,
  cellIndex: number,
  cache: IPathCache,
  cellWithSubItemsIndex = 0, // ex: ACCOUNT cell index
  headersCount = 0
): ICellPath[] => {
  const { parentsMapping, rootRowIds } = cache;
  const paths: ICellPath[] = [];
  const parentsIds = parentsMapping[rowId];

  if (!parentsIds) {
    // use rows mapping
    const updatedRowIndex = rootRowIds.indexOf(rowId) + headersCount;
    return [[{ rowIndex: updatedRowIndex, cellIndex }]];
  }
  parentsIds.forEach(parentId => {
    const updatedRowIndex = rows[parentId].children.indexOf(rowId);
    // we use cellWithSubItemsIndex instead of updatedCellIndex because we have to access to the next level
    const updatedCellPath = getCellPath(
      rows,
      parentId,
      cellWithSubItemsIndex,
      cache,
      cellWithSubItemsIndex,
      headersCount
    );
    const cellPathPart: ICellCoordinates = { rowIndex: updatedRowIndex, cellIndex };
    const newUpdatedQueries = updatedCellPath.map(updatedCellPathPart => [...updatedCellPathPart, cellPathPart]);
    paths.push(...newUpdatedQueries);
  });
  return paths;
};

export const updateCellValue = (
  currentCell: ITableCell,
  value: number,
  initial_value: number,
  mask: IMask
): ITableCell => {
  const formattedValue = formatValue(value, mask);
  const formattedInitialValue = formatValue(initial_value, mask);
  let isEdited = false;
  const isNotNull = value !== null || initial_value !== null;
  if (isNotNull) {
    isEdited = formattedValue !== formattedInitialValue;
  }
  const { className } = currentCell;
  // if it's an editable cell and cells with custom content
  if (currentCell.cellContent) {
    return update(currentCell, {
      cellContentProps: {
        value: { $set: value },
        isEdited: { $set: isEdited }
      }
    });
  }
  let newClassName = className || "";
  if (!isEdited) {
    newClassName = newClassName.replace(EDITED_CELL_CLASSNAME, "").trim();
  } else {
    newClassName = newClassName.includes(EDITED_CELL_CLASSNAME) ? className : EDITED_CELL_CLASSNAME;
  }
  return update(currentCell, { value: { $set: formattedValue }, className: { $set: newClassName } });
};

/**
 * triggered after successfully save
 * replace initial value by updated value and remove edited state
 */
export const persistCellValue = (currentCell: ITableCell): ITableCell => {
  const { className } = currentCell;
  // if it's an editable cell
  if (currentCell.cellContent) {
    return update(currentCell, {
      cellContentProps: {
        // @ts-ignore cellContentProps is generic
        initial_value: { $set: currentCell.cellContentProps.value },
        isEdited: { $set: false }
      }
    });
  }
  const newClassName = (className && className.replace(EDITED_CELL_CLASSNAME, "")) || "";
  return update(currentCell, { className: { $set: newClassName.trim() } });
};

export const getUpdatedTableRows = (
  computedCells: IComputedCells[],
  tableRows: ITableRow[],
  rows: IRows,
  cache: ICache,
  cellWithSubItemsIndex = 0,
  shouldPersist = false
): ITableRow[] => {
  const { columnsIndexMapping } = cache;
  let newTableRows = tableRows;
  computedCells.forEach(computedRowCells => {
    const row = rows[computedRowCells.label_code];
    if (row && (!row.is_hidden || row.is_child)) {
      Object.keys(computedRowCells.data).forEach(columnId => {
        const cellPaths = getCellPath(
          rows,
          computedRowCells.label_code,
          columnsIndexMapping[columnId],
          cache,
          cellWithSubItemsIndex,
          1
        );
        const rowCell = row.cells[columnId];
        cellPaths.forEach(cellPath => {
          const currentCell = getCell(newTableRows, cellPath);
          const { value } = computedRowCells.data[columnId];
          const newCell = shouldPersist
            ? persistCellValue(currentCell)
            : updateCellValue(currentCell, value, rowCell.initial_value, rowCell.mask || row.mask);
          newTableRows = setCell(newTableRows, cellPath, newCell);
        });
      });
    }
  });
  return newTableRows;
};

export const updateTableRows = (
  cells: IDataCoordinates[],
  tableRows: ITableRow[],
  rows: IRows,
  cache: ICache,
  updater: (
    currentCell: ITableCell<IDataCoordinates>,
    dataCoordinates?: IDataCoordinates
  ) => ITableCell<IDataCoordinates>,
  cellWithSubItemsIndex = 0
): ITableRow[] => {
  const { columnsIndexMapping } = cache;
  let newTableRows = tableRows;
  cells.forEach(cellCoordinates => {
    const { rowId, columnId } = cellCoordinates;
    const row = rows[rowId];
    if (row) {
      const cellPaths = getCellPath(rows, rowId, columnsIndexMapping[columnId], cache, cellWithSubItemsIndex, 1);
      cellPaths.forEach(cellPath => {
        const currentCell = getCell(newTableRows, cellPath);
        const newCell = updater(currentCell, { rowId, columnId });
        newTableRows = setCell(newTableRows, cellPath, newCell);
      });
    }
  });
  return newTableRows;
};

export const getPersistedTableRows = (
  computedCells: IComputedCells[],
  tableRows: ITableRow[],
  rows: IRows,
  cache: ICache,
  cellWithSubItemsIndex = 0
): ITableRow[] => {
  return getUpdatedTableRows(computedCells, tableRows, rows, cache, cellWithSubItemsIndex, true);
};

export const toEditableCell = <IGenericRows extends IRows<IRow> = IRows<IRow>>(
  tableRows: ITableRow[],
  rows: IGenericRows,
  rowId: string,
  columnId: string,
  cache: ICache,
  component = EditableCell,
  cellWithSubItemsIndex = 0
): ITableRow[] => {
  let newTableRows = tableRows;
  const { columnsIndexMapping } = cache;
  const cellPaths = getCellPath(rows, rowId, columnsIndexMapping[columnId], cache, cellWithSubItemsIndex, 1);
  const row = rows[rowId];
  const cell = row.cells[columnId];
  cellPaths.forEach(cellPath => {
    const currentCell = getCell(newTableRows, cellPath);
    const newCell = getEditableCell(
      { value: cell.value, isEdited: false, initial_value: cell.value },
      row.mask,
      rowId,
      columnId,
      currentCell.id,
      component
    );
    newTableRows = setCell(newTableRows, cellPath, newCell);
  });
  return newTableRows;
};

export const toDefaultCell = <IGenericRows extends IRows<IRow> = IRows<IRow>>(
  tableRows: ITableRow[],
  rows: IGenericRows,
  rowId: string,
  columnId: string,
  cache: ICache,
  cellWithSubItemsIndex = 0
): ITableRow[] => {
  let newTableRows = tableRows;
  const { columnsIndexMapping } = cache;
  const cellPaths = getCellPath(rows, rowId, columnsIndexMapping[columnId], cache, cellWithSubItemsIndex, 1);
  const row = rows[rowId];
  const cell = row.cells[columnId];
  const mask = cell?.mask || row?.mask;
  const getCustomClassName = mask?.is_percentage ? getClassName : undefined;

  cellPaths.forEach(cellPath => {
    const currentCell = getCell(newTableRows, cellPath);
    const newCell = {
      id: currentCell.id,
      value: formatValue(cell.value, row.mask),
      getClassName: getCustomClassName
    };
    newTableRows = setCell(newTableRows, cellPath, newCell);
  });
  return newTableRows;
};

export const sortTableColumns = (
  rows: ITableRow[],
  headerCells: ITableRow["cells"],
  columnsOrder: Record<string, number>
): ITableRow[] => {
  const sortedRows: ITableRow[] = [];

  for (const row of rows) {
    const newRow: ITableRow = { ...row };
    const newCells: ITableRow["cells"] = [];

    for (let i = 0; i < newRow.cells.length; i++) {
      const headerId = headerCells[i].id;
      const cell = newRow.cells[i];
      const newCell = { ...cell };

      if (newCell.subItems) {
        newCell.subItems = sortTableColumns(newCell.subItems, headerCells, columnsOrder);
      }

      if (headerId === "ACCOUNTS") {
        newCells[columnsOrder[`header_${headerId}`]] = newCell;
      } else {
        newCells[columnsOrder[headerId]] = newCell;
      }
    }

    newRow.cells = newCells;
    sortedRows.push(newRow);
  }

  return sortedRows;
};

export const getTableColumnsOrder = (headerCells: ITableRow["cells"]): Record<string, number> => {
  return headerCells.reduce<Record<string, number>>((acc, headerCell, index) => {
    if (headerCell.id === "ACCOUNTS") {
      acc[`header_${headerCell.id}`] = index;
    } else {
      acc[headerCell.id] = index;
    }
    return acc;
  }, {});
};
